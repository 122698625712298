import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Today from './../views/Today'
import Live from './../views/Live'
import P2015 from './../views/P2015'
import P2200 from './../views/P2200'
import Program from './../views/Program.vue'
import Bookmarks from './../views/Bookmarks.vue'
import Recommendations from './../views/Recommendations.vue'
import Search from './../views/Search.vue'
import Tv from './../views/Tv.vue'
import MediaLibrary from './../views/MediaLibrary.vue'
import MediaLibraryIndex from './../views/MediaLibraryIndex.vue'
import MediaLibraryChannels from './../views/MediaLibraryChannels.vue'
import MediaLibraryRecommendations from './../views/MediaLibraryRecommendations.vue'
import MediaLibraryLatest from './../views/MediaLibraryLatest.vue'
import MediaLibrarySeries from './../views/MediaLibrarySeries.vue'
import MediaLibraryMovies from './../views/MediaLibraryMovies.vue'
import Settings from './../views/Settings.vue'

const routes = [
    {
        path: '/today',
        name: 'today',
        component: Today,
        alias: '/',
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
    },
    {
        path: '/2015',
        name: 'p2015',
        component: P2015,
    },
    {
        path: '/2200',
        name: 'p2200',
        component: P2200,
    },
    {
        path: '/program',
        name: 'program',
        component: Program,
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
    },
    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: Bookmarks,
    },
    {
        path: '/recommendations',
        name: 'recommendations',
        component: Recommendations,
    },
    {
        path: '/tv',
        name: 'tv',
        component: Tv,
    },
    {
        path: '/medialibrary',
        component: MediaLibrary,
        children: [
            {
                path: '',
                name: 'medialibrary',
                component: MediaLibraryIndex,
            },
            {
                path: 'channels',
                name: 'medialibrary-channels',
                component: MediaLibraryChannels,
            },
            {
                path: 'recommendations',
                name: 'medialibrary-recommendations',
                component: MediaLibraryRecommendations,
            },
            {
                path: 'movies',
                name: 'medialibrary-movies',
                component: MediaLibraryMovies,
            },
            {
                path: 'latest',
                name: 'medialibrary-latest',
                component: MediaLibraryLatest,
            },
            {
                path: 'series',
                name: 'medialibrary-series',
                component: MediaLibrarySeries,
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
    },
]
const router = new VueRouter({
    scrollBehavior(to, from, savedPostion) {
        if (
            (from.query !== undefined && from.query.modal !== undefined) ||
            (to.query && to.query.modal !== undefined)
        ) {
            return savedPostion
        }
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: '/',
    routes,
})

export default router
