var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.anyDataReady)?_c('loading'):_c('div',[_c('div',{staticClass:"days d-flex py-2"},_vm._l((_vm.days),function(item,index){return _c('div',{key:'date-' + index,staticClass:"shadow-sm py-1 px-3 mx-1 bg-white text-center",class:{
                    'border border-2 border-primary':
                        item.date.format('YYYY-MM-DD') == _vm.dateQuery ||
                        (_vm.dateQuery === null && index == 0),
                },on:{"click":function($event){_vm.navigateDate(item.date.format('YYYY-MM-DD'))}}},[_c('div',{staticStyle:{"width":"30px"}},[_c('small',{staticClass:"text-muted"},[_c('span',{class:{
                                'text-danger':
                                    item.date.format('dd') == 'Sa' ||
                                    item.date.format('dd') == 'So',
                            }},[_vm._v(_vm._s(item.date.format('dd')))])]),_c('br'),_c('h4',{staticClass:"m-0 font-weight-bolder"},[_vm._v(" "+_vm._s(item.date.format('D'))+" ")]),(item.isToday)?_c('small',{staticStyle:{"margin-top":"-2px","display":"block"}},[_vm._v("heute")]):_vm._e()])])}),0),_c('div',{staticClass:"header bg-light",attrs:{"id":"stations"}},[_c('div',{staticClass:"stations d-flex py-1"},_vm._l((_vm.pDayFromNow),function(item,index){return _c('div',{key:'station-' + index,staticClass:"shadow-sm p-1 mx-1 bg-white d-flex flex-column align-items-center text-center  justify-content-center",class:{
                        'border border-primary border-2':
                            item.slug == _vm.stationQuery ||
                            (_vm.stationQuery === null && index == 0),
                    },on:{"click":function($event){return _vm.navigateStation(item)}}},[_c('img',{staticStyle:{"max-width":"60px"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                                item.slug +
                                '.png'}}),_c('div',{staticClass:"mt-2",staticStyle:{"width":"80px","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)]),_c('div',{staticClass:"content"},[_c('div',[(_vm.hProgram === null)?_c('div',{staticClass:"alert alert-primary mt-2 mb-0",staticStyle:{"position":"relative","z-index":"-1"}},[_vm._v(" Tippe auf "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}}),_vm._v(" um den Livestream zu starten oder auf "),_c('font-awesome-icon',{attrs:{"icon":['far', 'heart']}}),_vm._v(" um "),_c('strong',[_vm._v("10 Minuten vor Beginn")]),_vm._v(" der Sendung benachrichtigt zu werden. ")],1):_vm._e()]),_vm._l((_vm.activeStation.sections),function(item,index){return _c('div',{key:'section-' + index},[(item.data.length)?_c('h5',{staticClass:"ml-1 mb-2 mt-4 font-weight-bolder"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),_vm._l((item.data),function(item2,index2){return _c('div',{key:'program-' + index2,on:{"click":function($event){return _vm.handleBroadcastClick()}}},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_PROGRAM,"item":item2,"c":item2.c}})],1)})],2)})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }