<template>
    <div>
        <div
            v-if="layout != BROADCAST_LAYOUT_RECOMMENDATION"
            class="f d-flex align-items-center justify-content-between flex-nowrap m-0 my-2 p-2 shadow-sm bg-white"
            :id="c"
            @click="handleBroadcastClick(item)"
        >
            <div
                class="flex-fill"
                style="font-size: 115%"
                v-if="layout == BROADCAST_LAYOUT_LIVE"
            >
                <div class="d-flex align-items-center">
                    <div>
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                    item.station_slug +
                                    '.png'
                            "
                            style="max-width: 60px;"
                        />
                    </div>
                    <div class="ml-2 flex-fill">
                        <div class="small">
                            <span>
                                {{ dayjs(item.bc_start).format('HH:mm') }} -
                                {{ dayjs(item.bc_end).format('HH:mm') }}
                            </span>
                        </div>
                        <strong class="d-block text-gray-dark">{{
                            item.bc_title
                        }}</strong>
                        <div v-if="item.running">
                            <div class="progress" style="height: 3px">
                                <div
                                    class="progress-bar"
                                    :style="{
                                        width: remainingMinutesProgress(
                                            item.bc_start,
                                            item.bc_end
                                        ),
                                    }"
                                ></div>
                            </div>
                            <small
                                >läuft
                                {{ remainingMinutes(item.bc_end) }}</small
                            >
                        </div>
                        <div v-if="!item.running && !item.finished">
                            <small>beginnt {{ startIn(item.bc_start) }}</small>
                        </div>
                        <div v-if="item.finished">
                            <small>Sendung ist beendet</small>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex-fill"
                style="font-size: 110%"
                v-if="layout == BROADCAST_LAYOUT_PROGRAM"
            >
                <div class="small d-flex align-items-center">
                    <span
                        :class="{
                            'text-primary': isPrimeTime,
                            'text-muted': !isPrimeTime,
                        }"
                    >
                        {{ dayjs(item.bc_start).format('HH:mm') }} -
                        {{ dayjs(item.bc_end).format('HH:mm') }}
                    </span>
                    <span class="ml-1 text-muted">
                        <font-awesome-icon
                            :icon="['far', 'bell-slash']"
                            v-if="
                                item.series !== null &&
                                    !item.series.whitelist.includes(
                                        dayjs(item.bc_start).format('HH:mm')
                                    )
                            "
                        />
                        <font-awesome-icon
                            :icon="['far', 'bell']"
                            class="text-dark"
                            v-if="
                                item.series !== null &&
                                    item.series.whitelist.includes(
                                        dayjs(item.bc_start).format('HH:mm')
                                    )
                            "
                        />
                    </span>
                    <span
                        class="badge badge-primary text-white ml-2"
                        v-if="item.running"
                    >
                        <font-awesome-icon :icon="['fas', 'dot-circle']" />
                        LIVE
                    </span>
                    <span
                        class="badge badge-primary text-white ml-2"
                        v-if="isPrimeTime"
                    >
                        <font-awesome-icon :icon="['fas', 'medal']" />
                        PRIMETIME
                    </span>
                </div>
                <strong class="d-block text-gray-dark">{{
                    item.bc_title
                }}</strong>
                <div v-if="item.running">
                    <div class="progress" style="height: 3px">
                        <div
                            class="progress-bar"
                            :style="{
                                width: remainingMinutesProgress(
                                    item.bc_start,
                                    item.bc_end
                                ),
                            }"
                        ></div>
                    </div>
                    <small>läuft {{ remainingMinutes(item.bc_end) }}</small>
                </div>
            </div>

            <div
                class="d-flex"
                style="font-size: 115%"
                v-if="layout == BROADCAST_LAYOUT_SEARCH"
            >
                <div>
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                                item.station_slug +
                                '.png'
                        "
                        :alt="item.station_name"
                        style="max-width: 80px; border: 1px #eee solid"
                    />
                </div>
                <div class="ml-2">
                    <small
                        class="text-danger d-block"
                        v-if="dayjs().isBetween(item.bc_start, item.bc_end)"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'dot-circle']"
                            class="mr-1"
                        />Jetzt LIVE
                    </small>
                    <small class="font-weight-bold">
                        {{ dayjs(item.bc_start).format('dd, DD.MM.YYYY') }}
                    </small>
                    <small class="d-block text-gray-dark">
                        {{ item.station_name }}
                        <span class="text-primary mx-1">
                            {{ dayjs(item.bc_start).format('HH:mm') }} -
                            {{ dayjs(item.bc_end).format('HH:mm') }}
                        </span>
                    </small>
                    <strong class="d-block text-gray-dark">
                        {{ item.bc_title }}
                    </strong>
                </div>
            </div>
            <div
                class="flex-fill"
                style="font-size: 115%"
                v-if="layout == BROADCAST_LAYOUT_RECOMMENDATION_FULL"
            >
                <div class="d-flex align-items-center">
                    <div
                        style="min-width: 80px; background-size: cover"
                        :style="{
                            'background-image':
                                'url(' + item.imdb_image_url + ')',
                        }"
                        class="align-self-stretch"
                    ></div>
                    <div class="ml-2 flex-fill">
                        <div
                            class="small d-flex align-items-center text-gray-dark"
                        >
                            <span>
                                {{ dayjs(item.bc_start).format('HH:mm') }} -
                                {{ dayjs(item.bc_end).format('HH:mm') }}
                            </span>
                            <span
                                class="badge badge-primary text-white ml-1"
                                v-if="item.running"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'dot-circle']"
                                />
                                LIVE
                            </span>
                            <span class="mx-1"> |</span
                            ><span> {{ item.station_name }}</span>
                        </div>
                        <strong class="d-block text-gray-dark">{{
                            item.bc_title
                        }}</strong>
                        <div
                            class="small"
                            v-if="item.imdb_rating_count !== null"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'star']"
                                v-for="n in Math.ceil(stars)"
                                :key="'aacc' + n"
                            />
                            <font-awesome-icon
                                :icon="['far', 'star']"
                                v-for="n in 5 - Math.ceil(stars)"
                                :key="'aabb' + n"
                            />

                            <span class="ml-2"
                                >{{ item.imdb_rating_count }} Bewertungen</span
                            >
                        </div>
                        <div class="small text-muted  mb-1">
                            {{ item.imdb_genres.split(',').join(', ') }}
                        </div>
                        <div v-if="item.running">
                            <div class="progress" style="height: 3px">
                                <div
                                    class="progress-bar"
                                    :style="{
                                        width: remainingMinutesProgress(
                                            item.bc_start,
                                            item.bc_end
                                        ),
                                    }"
                                ></div>
                            </div>
                            <small
                                >läuft
                                {{ remainingMinutes(item.bc_end) }}</small
                            >
                        </div>
                        <div v-if="!item.running">
                            <small>beginnt {{ startIn(item.bc_start) }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex"
                style="font-size: 115%"
                v-if="layout == BROADCAST_LAYOUT_BOOKMARK"
            >
                <div class="d-flex align-items-center">
                    <div>
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                    item.station_slug +
                                    '.png'
                            "
                            style="max-width: 60px;"
                        />
                    </div>
                    <div class="ml-2 flex-fill">
                        <small class="d-block text-gray-dark">
                            <span>
                                {{ dayjs(item.bc_start).format('HH:mm') }} -
                                {{ dayjs(item.bc_end).format('HH:mm') }}
                            </span>
                            <span
                                class="badge badge-primary text-white ml-1"
                                v-if="item.running"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'dot-circle']"
                                />
                                LIVE
                            </span>
                        </small>
                        <strong class="d-block text-gray-dark">{{
                            item.bc_title
                        }}</strong>
                        <div v-if="item.running">
                            <div class="progress" style="height: 3px">
                                <div
                                    class="progress-bar"
                                    :style="{
                                        width: remainingMinutesProgress(
                                            item.bc_start,
                                            item.bc_end
                                        ),
                                    }"
                                ></div>
                            </div>
                            <small
                                >läuft
                                {{ remainingMinutes(item.bc_end) }}</small
                            >
                        </div>
                        <div v-if="!item.running">
                            <small>beginnt {{ startIn(item.bc_start) }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="ml-2 p-1"
                v-if="
                    item.running ||
                        (layout == BROADCAST_LAYOUT_BOOKMARK &&
                            startInSeconds(item.bc_start) < 600)
                "
            >
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
            <div
                class="ml-2 p-1"
                v-if="
                    startInSeconds(item.bc_start) > 600 &&
                        !item.finished &&
                        !item.running &&
                        !item.loading &&
                        item.series !== null
                "
            >
                <font-awesome-icon
                    :icon="['fas', 'heart']"
                    class="text-primary"
                />
            </div>
            <div
                class="ml-2 p-1"
                v-if="
                    !item.finished &&
                        !item.running &&
                        !item.loading &&
                        item.series === null
                "
            >
                <font-awesome-icon :icon="['far', 'heart']" class="text-dark" />
            </div>
            <div class="ml-2 p-1" v-if="!item.running && item.loading">
                <b-spinner
                    small
                    class="text-muted"
                    label="Laden..."
                ></b-spinner>
            </div>
        </div>
        <div v-else>
            <div class="m-1" :id="c" @click="handleBroadcastClick(item)">
                <div
                    class="mb-1 position-relative"
                    style="border-radius: 1px; width: 150px; height: 220px; background-size: cover"
                    :style="{
                        'background-image': 'url(' + item.imdb_image_url + ')',
                    }"
                >
                    <div
                        class="w-100 h-100 position-absolute"
                        style="background: #000; opacity: 0.25; z-index: 1000;"
                    ></div>
                    <div
                        class="d-flex position-relative align-items-center justify-content-center h-100 w-100"
                        style="z-index: 1001"
                    >
                        <font-awesome-icon
                            v-if="item.running"
                            class="text-white"
                            style="font-size: 35px"
                            :icon="['fas', 'play']"
                        />
                        <span v-else>
                            <font-awesome-icon
                                v-if="item.series !== null"
                                class="text-white"
                                style="font-size: 35px"
                                :icon="['fas', 'heart']"
                            />
                            <font-awesome-icon
                                v-else
                                class="text-white"
                                style="font-size: 35px"
                                :icon="['far', 'heart']"
                            />
                        </span>
                    </div>
                </div>
                <div v-if="item.running">
                    <div class="progress" style="height: 3px">
                        <div
                            class="progress-bar"
                            :style="{
                                width: remainingMinutesProgress(
                                    item.bc_start,
                                    item.bc_end
                                ),
                            }"
                        ></div>
                    </div>
                    <span class="text-muted"
                        >läuft {{ remainingMinutes(item.bc_end) }}</span
                    >
                </div>
                <div v-else class="text-muted">
                    <span v-if="dayjs(item.bc_start).isSame(new Date(), 'day')">
                        Heute um
                        {{ dayjs(item.bc_start).format('HH:mm') }} Uhr</span
                    >
                    <span v-else>
                        {{ dayjs(item.bc_start).format('dd, DD.MM') }},
                        {{ dayjs(item.bc_start).format('HH:mm') }} Uhr</span
                    >
                </div>
                <h6 class="my-0 page-title">{{ item.bc_title }}</h6>
                <span class="small text-muted">{{
                    item.imdb_genres.split(',').join(', ')
                }}</span>
            </div>
        </div>
        <b-modal
            v-model="ad.modal.show"
            @hidden="adModalHidden()"
            @shown="adCountDownTimer()"
            no-close-on-backdrop
            centered
            modal-class="modal-fullscreen"
            body-class="d-flex align-items-center justify-content-center"
            hide-header
            hide-footer
            no-fade
        >
            <div class="text-center">
                <font-awesome-icon
                    style="font-size: 50px"
                    :icon="['fas', 'ad']"
                />
                <h2>Werbung beginnt in</h2>
                <h2>{{ ad.countDown }}s</h2>
            </div>
        </b-modal>
        <b-modal
            v-model="broadcast.modal.show"
            scrollable
            hide-footer
            centered
            @hidden="onBroadcastModalHidden()"
        >
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="broadcast.modal.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                    <b-dropdown
                        dropleft
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <font-awesome-icon
                                class="text-dark"
                                :icon="['fas', 'ellipsis-v']"
                            />
                        </template>
                        <b-dropdown-item
                            @click="
                                confirm(
                                    'Sendung löschen',
                                    'Möchtest du die Sendung aus deinen Sendungen entfernen?',
                                    popSeries
                                )
                            "
                        >
                            Nicht mehr folgen
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>

            <div v-if="!seriesExists">
                Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.
            </div>
            <div v-else>
                <div v-if="!broadcast.modal.loading">
                    <div class="d-flex align-items-center mb-1">
                        <img
                            v-if="imdb !== undefined"
                            :src="selectedBroadcast.imdb_image_url"
                            style="max-width: 85px"
                        />
                        <img
                            v-else
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                    selectedBroadcast.station_slug +
                                    '.png'
                            "
                            style="max-width: 75px"
                        />
                        <div class="p-2">
                            <span class="text-muted">{{
                                selectedBroadcast.station_name
                            }}</span>
                            <h5
                                class=" font-weight-bold mb-0"
                                v-if="imdb !== undefined"
                            >
                                {{ selectedBroadcast.bc_title }}
                            </h5>
                            <h5 class=" font-weight-bold mb-0" v-else>
                                {{ activeSeries.series_name }}
                            </h5>
                            <div
                                v-if="activeSeries.series_rating_count !== null"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'star']"
                                    v-for="n in Math.ceil(stars)"
                                    :key="'aacc' + n"
                                />
                                <font-awesome-icon
                                    :icon="['far', 'star']"
                                    v-for="n in 5 - Math.ceil(stars)"
                                    :key="'aabb' + n"
                                />

                                <span class="ml-2"
                                    >{{
                                        activeSeries.series_rating_count
                                    }}
                                    Bewertungen</span
                                >
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-block mb-2"
                        v-if="!isUserSeries"
                        @click="pushSeries()"
                    >
                        <font-awesome-icon :icon="['far', 'heart']" />
                        Sendung merken
                    </button>
                    <div v-if="isUserSeries">
                        <div class="text-primary text-center font-weight-bold">
                            <font-awesome-icon :icon="['fas', 'heart']" />
                            Sendung wurde gemerkt
                        </div>
                        <hr />
                        <div
                            class="alert alert-primary"
                            v-if="hTvSeries === null"
                        >
                            Für Uhrzeiten mit
                            <font-awesome-icon
                                :icon="['far', 'bell']"
                                class="text-primary"
                            />
                            erhälst du Benachrichtigungen. Für Uhrzeiten mit
                            <font-awesome-icon
                                :icon="['far', 'bell-slash']"
                                class="text-muted"
                            />
                            erhälst du <u>keine</u> Benachrichtigungen.<br />
                            Tippe auf
                            <button
                                class="btn btn-sm btn-outline-dark "
                                style="padding: 0.20rem; 0.625rem"
                            >
                                20:15
                                <font-awesome-icon
                                    :icon="['far', 'bell-slash']"
                                />
                            </button>
                            um die Uhrzeiten anzupassen.
                        </div>
                        <h5 class="page-title mb-0">
                            Ausstrahlungen der nächsten 14 Tage
                        </h5>
                        <div class="mt-2">
                            <div
                                class="d-inline"
                                v-for="(item,
                                index) in activeSeries.time_groups"
                                :key="'aaa-' + index"
                            >
                                <button
                                    v-if="
                                        activeUserSeries.whitelist.includes(
                                            item.value
                                        )
                                    "
                                    style="width: 85px"
                                    class="btn btn-sm btn-primary m-1"
                                    @click="popUserSeriesWhitelist(item)"
                                >
                                    {{ item.value }}
                                    <font-awesome-icon
                                        v-if="!item.loading"
                                        :icon="['far', 'bell']"
                                    />
                                    <b-spinner
                                        small
                                        v-else
                                        label="Laden..."
                                    ></b-spinner>
                                </button>
                                <button
                                    v-if="
                                        !activeUserSeries.whitelist.includes(
                                            item.value
                                        )
                                    "
                                    style="width: 85px"
                                    class="btn btn-sm btn-outline-dark m-1"
                                    @click="pushUserSeriesWhitelist(item)"
                                >
                                    {{ item.value }}
                                    <font-awesome-icon
                                        v-if="!item.loading"
                                        :icon="['far', 'bell-slash']"
                                    />
                                    <b-spinner
                                        small
                                        v-else
                                        label="Laden..."
                                    ></b-spinner>
                                </button>
                            </div>
                        </div>

                        <table class="table table-striped table-sm w-100 my-2">
                            <tbody>
                                <tr
                                    v-for="(item,
                                    index) in activeSeries.broadcasts"
                                    :key="'zzzk-' + index"
                                >
                                    <td class="text-left" style="width: 85px">
                                        {{
                                            dayjs(item.start).format(
                                                'DD.MM.YYYY'
                                            )
                                        }}
                                    </td>
                                    <!--<td class="text-left" style="width: 50px">-->
                                    <td class="text-left">
                                        {{ dayjs(item.start).format('HH:mm') }}
                                    </td>
                                    <!--
                                    <td class="text-left">
                                        {{ item.title }}
                                    </td>
                                    -->
                                    <td class="text-center" style="width: 30px">
                                        <font-awesome-icon
                                            :icon="['far', 'bell']"
                                            class="text-primary"
                                            v-if="
                                                activeUserSeries.whitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                        <font-awesome-icon
                                            :icon="['far', 'bell-slash']"
                                            class="text-dark"
                                            v-if="
                                                !activeUserSeries.whitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="45%"></b-skeleton>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="google.modal.show" scrollable hide-footer centered>
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div class="p-2 h5 mb-0" @click="google.modal.show = false">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            Klicke auf "Sender suchen" und wähle einen passenden Sender aus
            <button
                type="button"
                class="btn btn-primary btn-block mt-2"
                @click="addStation()"
            >
                Sender suchen
            </button>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
const getCountdown = start => {
    const m = Math.ceil(dayjs(start).diff(dayjs(), 'minutes', true))

    const MINUTE = 1
    const HOUR = 60
    const DAY = HOUR * 24

    const days = Math.floor(m / DAY)
    const hDiff = m % DAY
    const hours = Math.floor(hDiff / HOUR)
    const mDiff = hDiff % HOUR
    const minutes = Math.floor(mDiff / MINUTE)

    return [days, hours, minutes]
}
export default {
    name: 'Broadcast',
    props: ['item', 'layout', 'c', 'imdb'],
    data: function() {
        return {
            hTvSeries: null || localStorage.getItem('hTvSeries'),
            dayjs,
            ad: {
                modal: {
                    show: false,
                },
                countDown: 3,
                response: {},
            },
            broadcast: {
                modal: {
                    show: false,
                    loading: false,
                    touched: false,
                },
                selected: {},
            },
            google: {
                modal: {
                    show: false,
                    data: {},
                },
            },
        }
    },
    computed: {
        ...mapGetters('series', {
            userSeries: 'series',
        }),
        ...mapGetters('series', {
            activeSeries: 'first',
        }),
        stars() {
            const x1 = 1
            const y1 = 10
            const x2 = 1
            const y2 = 5
            if (
                Object.keys(this.activeSeries).length &&
                this.activeSeries.series_rating_value
            ) {
                return (
                    ((this.activeSeries.series_rating_value - x1) * (y2 - x2)) /
                        (y1 - x1) +
                    x2
                )
            } else {
                if (this.item.imdb_rating_count) {
                    return (
                        ((this.item.imdb_rating_value - x1) * (y2 - x2)) /
                            (y1 - x1) +
                        x2
                    )
                }
                return null
            }
        },
        selectedBroadcast() {
            return this.broadcast.selected
        },
        activeUserSeries() {
            const f = this.userSeries.find(
                item => item.series_id == this.selectedBroadcast.series_id
            )
            if (f !== undefined) {
                return f
            }
            return {}
        },
        isUserSeries() {
            if (Object.keys(this.activeUserSeries).length) {
                return true
            }
            return false
        },
        isPrimeTime() {
            return dayjs(this.item.bc_start)
                .startOf('day')
                .add(20, 'hours')
                .add(15, 'minutes')
                .isSame(this.item.bc_start)
        },
        seriesExists() {
            return this.selectedBroadcast.series_id > 0
        },
    },
    methods: {
        hideHint() {
            this.hTvSeries = 1
            localStorage.setItem('hTvSeries', this.hTvSeries)
        },
        onBroadcastModalHidden() {
            if (this.broadcast.modal.touched) {
                this.$emit('modal-hidden')
            }
        },
        confirm(title, description, okFunc) {
            this.$bvModal
                .msgBoxConfirm(description, {
                    title,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Ja, löschen',
                    cancelTitle: 'Nein, abbrechen',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        okFunc()
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        syncSeries(items) {
            this.$store.dispatch('series/sync', {
                data: {
                    items,
                },
            })
        },

        async updateSeriesWhitelist(items) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch('series/update', {
                        data: {
                            id: this.activeSeries.series_id,
                            update: {
                                whitelist: JSON.stringify(items),
                            },
                        },
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                        reject()
                    })
                    .then(() => {
                        this.nativeBridge('showToast', 'Änderung gespeichert')
                        this.broadcast.modal.touched = true
                        resolve()
                    })
            })
        },
        async pushUserSeriesWhitelist(item) {
            this.hideHint()
            if (!item.loading) {
                let temp = _.cloneDeep(this.activeUserSeries.whitelist)
                temp.push(item.value)
                console.log(temp)
                item.loading = true
                await this.updateSeriesWhitelist(temp)
                item.loading = false
            }
        },
        async popUserSeriesWhitelist(item) {
            this.hideHint()
            if (!item.loading) {
                let temp = _.cloneDeep(this.activeUserSeries.whitelist)
                const idx = temp.indexOf(item.value)
                if (idx > -1) {
                    temp.splice(idx, 1)
                    item.loading = true
                    await this.updateSeriesWhitelist(temp)
                    item.loading = false
                }
            }
        },

        pushSeries() {
            let temp = _.cloneDeep(this.userSeries)
            temp.push({
                series_id: this.selectedBroadcast.series_id,
                bc_id: this.selectedBroadcast.bc_id,
                bc_start: this.selectedBroadcast.bc_start,
            })
            this.syncSeries(temp)
        },
        popSeries() {
            let temp = _.cloneDeep(this.userSeries)
            const idx = temp.findIndex(
                item2 => item2.series_id == this.selectedBroadcast.series_id
            )
            temp.splice(idx, 1)
            this.syncSeries(temp)
            this.broadcast.modal.show = false
        },
        openBroadcastModal(item) {
            this.broadcast.selected = item
            this.broadcast.modal.show = true
            console.log(
                'openBroadcastModal',
                item,
                this.selectedBroadcast.series_id > 0
            )
            if (this.selectedBroadcast.series_id > 0) {
                this.broadcast.modal.loading = true
                this.$store
                    .dispatch('series/show', {
                        data: {
                            series_id: this.selectedBroadcast.series_id,
                        },
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                    })
                    .then(() => {
                        this.broadcast.modal.loading = false
                    })
            }
        },
        adModalHidden() {
            this.nativeBridge(
                this.ad.response.data['fn'],
                this.ad.response.data['params']
            )
        },
        adCountDownTimer() {
            if (this.ad.countDown > 0) {
                setTimeout(() => {
                    this.ad.countDown -= 1
                    this.adCountDownTimer()
                }, 1000)
            } else {
                this.ad.modal.show = false
                setTimeout(() => {
                    this.ad.countDown = 3
                }, 500)
            }
        },
        addStation() {
            this.nativeBridge('addStation', this.google.modal.data)
        },
        handleBroadcastClick(item) {
            if (
                item.running ||
                (this.layout == this.BROADCAST_LAYOUT_BOOKMARK &&
                    this.startInSeconds(item.bc_start) < 600)
            ) {
                const token = this.$store.state.auth.token
                axios
                    .post(
                        '/activity/start-stream',
                        {
                            station_slug: item.station_slug,
                            bc_id: item.bc_id,
                            ad_available:
                                localStorage.getItem('ad_available') == 'true',
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then(response => {
                        if (response.data['fn'] == 'addStation') {
                            this.google.modal.show = true
                            this.google.modal.data = response.data['params']
                            this.google.modal.data.url =
                                'https://www.google.de/search?q=' +
                                this.google.modal.data.label +
                                '+stream+ohne+anmeldung'
                        } else {
                            if (response.data['params'].show_ad) {
                                localStorage.setItem('last_ad_at', dayjs())
                                localStorage.setItem('ad_loaded', 0)
                                this.ad.modal.show = true
                                this.ad.response = response
                            } else {
                                this.nativeBridge(
                                    response.data['fn'],
                                    response.data['params']
                                )
                            }
                        }
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                    })
            } else {
                this.openBroadcastModal(item)
            }
        },
        startInSeconds(start) {
            return dayjs(start).diff(dayjs(), 'seconds')
        },
        startIn(start) {
            let cd = getCountdown(start)
            let t = 'in '

            if (cd[0] > 0) {
                if (cd[0] == 1) {
                    t += '1T'
                } else {
                    t += cd[0] + 'T'
                }
            }

            if (cd[1] > 0) {
                if (cd[0] > 0) {
                    t += ' '
                }
                if (cd[1] == 1) {
                    t += '1Std'
                } else {
                    t += cd[1] + 'Std'
                }
            }
            if (cd[2] > 0) {
                if (cd[0] > 0 || cd[1] > 0) {
                    t += ' '
                }
                if (cd[2] == 1) {
                    t += '1Min'
                } else {
                    t += cd[2] + 'Min'
                }
            }
            return t
        },
        remainingMinutes(end) {
            let cd = getCountdown(end)
            let t = 'noch '
            if (cd[1] > 0) {
                if (cd[1] == 1) {
                    t += '1Std'
                } else {
                    t += cd[1] + 'Std'
                }
            }
            if (cd[2] > 0) {
                if (cd[1] > 0) {
                    t += ', '

                    if (cd[2] == 1) {
                        t += '1Min'
                    } else {
                        t += cd[2] + 'Min'
                    }
                } else {
                    if (cd[2] == 1) {
                        t += '1 Minute'
                    } else {
                        t += cd[2] + ' Minuten'
                    }
                }
            }
            return t
        },
        remainingMinutesProgress(start, end) {
            return (
                100 -
                (dayjs(end).diff(dayjs(), 'minutes') /
                    dayjs(end).diff(dayjs(start), 'minutes')) *
                    100 +
                '%'
            )
        },
    },
    mounted() {},
}
</script>
