<template>
    <div>
        <loading v-if="!anyDataReady" />
        <div v-else>
            <div class="days d-flex py-2">
                <div
                    v-for="(item, index) in days"
                    :key="'date-' + index"
                    class="shadow-sm py-1 px-3 mx-1 bg-white text-center"
                    :class="{
                        'border border-2 border-primary':
                            item.date.format('YYYY-MM-DD') == dateQuery ||
                            (dateQuery === null && index == 0),
                    }"
                    @click="navigateDate(item.date.format('YYYY-MM-DD'))"
                >
                    <div style="width: 30px">
                        <small class="text-muted">
                            <span
                                :class="{
                                    'text-danger':
                                        item.date.format('dd') == 'Sa' ||
                                        item.date.format('dd') == 'So',
                                }"
                                >{{ item.date.format('dd') }}</span
                            >
                        </small>
                        <br />
                        <h4 class="m-0 font-weight-bolder">
                            {{ item.date.format('D') }}
                        </h4>
                        <small
                            v-if="item.isToday"
                            style="margin-top: -2px;display: block"
                            >heute</small
                        >
                    </div>
                </div>
            </div>
            <div class="header bg-light" id="stations">
                <div class="stations d-flex py-1">
                    <div
                        class="shadow-sm p-1 mx-1 bg-white d-flex flex-column align-items-center text-center  justify-content-center"
                        v-for="(item, index) in pDayFromNow"
                        :key="'station-' + index"
                        :class="{
                            'border border-primary border-2':
                                item.slug == stationQuery ||
                                (stationQuery === null && index == 0),
                        }"
                        @click="navigateStation(item)"
                    >
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                    item.slug +
                                    '.png'
                            "
                            style="max-width: 60px"
                        />
                        <div
                            class="mt-2"
                            style="width: 80px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-weight: bold"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div>
                    <div
                        class="alert alert-primary mt-2 mb-0"
                        style="position: relative; z-index: -1"
                        v-if="hProgram === null"
                    >
                        Tippe auf
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        um den Livestream zu starten oder auf
                        <font-awesome-icon :icon="['far', 'heart']" />
                        um
                        <strong>10 Minuten vor Beginn</strong> der Sendung
                        benachrichtigt zu werden.
                    </div>
                </div>
                <div
                    v-for="(item, index) in activeStation.sections"
                    :key="'section-' + index"
                >
                    <h5
                        class="ml-1 mb-2 mt-4 font-weight-bolder"
                        v-if="item.data.length"
                    >
                        {{ item.label }}
                    </h5>
                    <div
                        v-for="(item2, index2) in item.data"
                        :key="'program-' + index2"
                        @click="handleBroadcastClick()"
                    >
                        <broadcast
                            :layout="BROADCAST_LAYOUT_PROGRAM"
                            :item="item2"
                            :c="item2.c"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'
export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function() {
        return {
            hProgram: null || localStorage.getItem('hProgram'),
            loading: false,
            search: {
                keyword: '',
                items: [],
                loading: false,
                request: null,
            },
            selectedStation: null,
            selectedDate: null,
        }
    },
    watch: {
        'search.keyword': {
            handler() {
                if (this.search.loading) this.cancelRequest()
                if (this.search.keyword.length) {
                    this.search.loading = true
                    this.debSearch()
                } else {
                    this.$store.commit('program/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
        $route: {
            handler() {
                this.search.keyword = ''
            },
        },
    },
    computed: {
        ...mapGetters('program', {
            days: 'dates',
        }),
        ...mapGetters('program', {
            stations: 'pStations',
        }),
        ...mapGetters('program', {
            pDayFromNow: 'pDay',
        }),
        ...mapGetters('program', {
            searchResults: 'search',
        }),
        activeStation() {
            if (this.pDayFromNow.length) {
                return this.pDayFromNow.find((item, index) => {
                    return (
                        item.slug == this.stationQuery ||
                        (index == 0 && this.stationQuery == null)
                    )
                })
            } else {
                return {}
            }
        },
        dateQuery() {
            //return this.$route.query.date ? this.$route.query.date : null
            return this.selectedDate ? this.selectedDate : null
        },
        stationQuery() {
            //return this.$route.query.station ? this.$route.query.station : null
            return this.selectedStation ? this.selectedStation.slug : null
        },
        anyDataReady() {
            return (
                this.$store.state.program.init &&
                this.$store.state.series.init &&
                !this.loading
            )
        },
    },
    methods: {
        handleBroadcastClick() {
            this.hProgram = 1
            localStorage.setItem('hProgram', this.hProgram)
        },
        onKeyUp() {
            if (this.search.loading) this.cancelRequest()
            if (this.search.keyword.length >= 2) {
                this.debSearch()
            } else {
                this.searchResults = []
            }
        },
        navigateDate(value) {
            /*
                if (this.dateQuery != value) {
                    this.loading = true
                    let query = {
                        date: value,
                    }
                    if (this.stationQuery) {
                        query.station = this.stationQuery
                    }
                    query.api_token = this.$route.query.api_token
                    this.$router.push({ path: 'program', query })
                    this.fetchProgram()
                }
                */
            this.selectedDate = value
            this.fetchProgram()
        },
        navigateStation(item) {
            /*
                if (this.stationQuery != value) {
                    let query = {
                        station: value,
                    }
                    if (this.dateQuery) {
                        query.date = this.dateQuery
                    }
                    query.api_token = this.$route.query.api_token
                    this.$router.push({ path: 'program', query })
                }*/
            this.selectedStation = item
        },
        cancelRequest() {
            this.search.request.cancel()
        },
        searchProgram() {
            const axiosSource = axios.CancelToken.source()
            this.search.request = { cancel: axiosSource.cancel }
            this.search.loading = true
            this.$store
                .dispatch('program/search', {
                    data: {
                        keyword: this.search.keyword,
                    },
                    options: {
                        cancelToken: axiosSource.token,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.search.loading = false
                })
        },
        fetchProgram() {
            this.loading = true
            this.$store
                .dispatch('program/fetch', {
                    data: {
                        station: this.stationQuery,
                        date: this.dateQuery,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
        fetchBookmarks() {
            this.$store
                .dispatch('bookmarks/fetch')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
    },
    created() {
        window.onscroll = () => {
            let header = document.getElementById('stations')
            if (header) {
                let sticky = header.offsetTop
                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky')
                } else {
                    header.classList.remove('sticky')
                }
            }
        }
        this.debSearch = _.debounce(this.searchProgram, 250)
        //this.fetchProgram()
        //this.fetchBookmarks()
    },
}
</script>
