<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="page-title mb-0">Alle Kanäle</h5>
            <p class="text-muted mb-0">
                Entdecke Videos aus {{ channels.length }} Kanälen
            </p>
            <channel
                v-for="(item, index) in channels"
                :key="'channel-' + index"
                :item="item"
                :layout="1"
            />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Channel from '../components/medialibrary/Channel'
import Loading from '../components/Loading'
export default {
    components: {
        Channel,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            loading: true,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            channels: 'channels',
        }),
    },
    methods: {
        fetchChannels() {
            this.loading = true
            this.$store
                .dispatch('medialibrary/channels')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        if (!this.channels.length) {
            this.fetchChannels()
        } else {
            this.loading = false
        }
    },
}
</script>
