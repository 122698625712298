<template>
    <div>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="45%"></b-skeleton>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        layout: {
            type: Number,
            required: false,
        },
    },
}
</script>
