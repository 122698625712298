<template>
    <router-view></router-view>
</template>
<script>
import { EventBus } from '@/event-bus'

export default {
    data() {
        return {
            latest: false,
            recommendations: false,
            movies: false,
            channels: false,
            genres: false,
        }
    },
    computed: {
        fetched() {
            const state = this.$store.state.medialibrary

            return (
                state.channels.data.length > 0 &&
                state.genres.data.length > 0 &&
                state.recommendations.data.length > 0 &&
                state.latest.data.length > 0
            )
        },
        allFetched() {
            return (
                this.latest &&
                this.recommendations &&
                this.movies &&
                this.channels &&
                this.genres
            )
        },
    },
    watch: {
        $route(to, from) {},
        allFetched(to, from) {
            if (to === true) {
                localStorage.setItem('media_library_updated_at', dayjs())
            }
        },
    },
    methods: {
        fetchLatest() {
            this.$store
                .dispatch('medialibrary/latest')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.latest = true
                })
        },
        fetchRecommendations() {
            this.$store
                .dispatch('medialibrary/recommendations')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.recommendations = true
                })
        },
        fetchMovies() {
            this.$store
                .dispatch('medialibrary/movies')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.movies = true
                })
        },
        fetchChannels() {
            this.$store
                .dispatch('medialibrary/channels')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.channels = true
                })
        },
        fetchGenres() {
            this.$store
                .dispatch('medialibrary/genres')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.genres = true
                })
        },
        checkIfDocumentNeedsMoreContent() {
            if (
                document.documentElement.scrollTop + window.innerHeight >=
                document.documentElement.offsetHeight * (4 / 5)
            ) {
                console.log('Event: bottomed')
                EventBus.$emit('bottomed')
            }
        },
        checkIfModalNeedsMoreContent(elem) {
            if (
                elem.scrollTop + elem.offsetHeight >=
                elem.scrollHeight * (4 / 5)
            ) {
                /*if (
                elem.scrollTop + elem.offsetHeight + offset >=
                elem.scrollHeight
            ) {
                */
                console.log('Event: modal-bottomed')
                EventBus.$emit('modal-bottomed')
            }
        },
    },
    mounted() {
        const mediaLibraryUpdatedAt = localStorage.getItem(
            'media_library_updated_at'
        )
        console.log(mediaLibraryUpdatedAt, this.fetched)
        if (
            mediaLibraryUpdatedAt === null ||
            dayjs(mediaLibraryUpdatedAt).isBefore(
                dayjs().add('-1', 'minutes')
            ) ||
            !this.fetched
        ) {
            console.log('loading...')
            this.fetchChannels()
            this.fetchLatest()
            this.fetchRecommendations()
            this.fetchMovies()
            this.fetchGenres()
        }

        //localStorage.setItem('media_library_updated_at', dayjs())

        this.throttledDocument = _.throttle(
            this.checkIfDocumentNeedsMoreContent,
            1000,
            { leading: false, trailing: true }
        )

        window.addEventListener('scroll', this.throttledDocument)

        this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
            let t = bvEvent.target.querySelector('.modal-body')
            this.throttledModal = _.throttle(
                () => this.checkIfModalNeedsMoreContent(t),
                1000,
                { leading: false, trailing: true }
            )
            setTimeout(() => {
                t.addEventListener('scroll', this.throttledModal)
            }, 1000)
        })

        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            let t = bvEvent.target.querySelector('.modal-body')
            t.removeEventListener('scroll', this.throttledModal)
        })
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.throttledDocument)
    },
}
</script>
