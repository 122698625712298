var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.anyDataReady)?_c('div',[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}}),_c('b-skeleton',{attrs:{"width":"45%"}})],1):_vm._e(),(_vm.anyDataReady)?_c('div',[_c('h5',{staticClass:"font-weight-bold my-0 mb-1"},[_vm._v("Film Empfehlungen")]),(_vm.hTvMovies === null)?_c('div',{staticClass:"alert alert-primary"},[_vm._v(" Hier findest du TOP Filme der nächsten 7 Tage. Tippe auf "),_c('font-awesome-icon',{attrs:{"icon":['far', 'heart']}}),_vm._v(" um "),_c('strong',[_vm._v("10 Minuten vor Beginn")]),_vm._v(" des Films benachrichtigt zu werden. ")],1):_vm._e(),_c('div',{staticClass:"days d-flex py-2"},_vm._l((_vm.days),function(item,index){return _c('div',{key:'date-' + index,staticClass:"shadow-sm py-1 px-3 mx-1 bg-white text-center",class:{
                    'text-primary': item.isToday,
                    'border border-2 border-primary':
                        item.format('DD-MM-YYYY') == _vm.day ||
                        (_vm.day === null && index == 0),
                },on:{"click":function($event){return _vm.navigateDate(item)}}},[_c('small',{staticClass:"text-muted"},[_c('span',{class:{
                            'text-danger':
                                item.format('dd') == 'Sa' ||
                                item.format('dd') == 'So',
                        }},[_vm._v(_vm._s(item.format('dd')))])]),_c('br'),_c('h4',{staticClass:"m-0 font-weight-bolder"},[_vm._v(" "+_vm._s(item.format('D'))+" ")]),(item.isSame(new Date(), 'day'))?_c('small',{staticStyle:{"margin-top":"-2px","display":"block"}},[_vm._v("heute")]):_vm._e()])}),0),_vm._l((_vm.future),function(item,index){return _c('div',{key:'f-' + index},_vm._l((item.items),function(item2,index2){return _c('broadcast',{key:'f2' + index2,attrs:{"layout":_vm.BROADCAST_LAYOUT_RECOMMENDATION_FULL,"item":item2,"c":item2.c,"imdb":""},on:{"click":function($event){}}})}),1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }