<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="font-weight-bold my-0 mb-1">Heute Abend im TV</h5>
            <div class="my-2">
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2015',
                        'btn-outline-primary': selected != 'p2015',
                    }"
                    @click="selected = 'p2015'"
                    v-if="
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(22, 'hours')
                        ) &&
                            dayjs().isAfter(
                                dayjs()
                                    .startOf('day')
                                    .add(5, 'hours')
                            )
                    "
                >
                    ab 20:15
                </button>
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2200',
                        'btn-outline-primary': selected != 'p2200',
                    }"
                    @click="selected = 'p2200'"
                    v-if="
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(23, 'hours')
                        ) &&
                            dayjs().isAfter(
                                dayjs()
                                    .startOf('day')
                                    .add(5, 'hours')
                            )
                    "
                >
                    ab 22:00
                </button>
                <button
                    class="btn"
                    :class="{
                        'btn-primary': selected == 'p2300',
                        'btn-outline-primary': selected != 'p2300',
                    }"
                    @click="selected = 'p2300'"
                >
                    ab 23:00
                </button>
            </div>
            <div
                class="alert alert-primary"
                v-if="
                    hToday2015 === null &&
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(20, 'hours')
                                .add(5, 'minutes')
                        )
                "
            >
                Tippe auf
                <font-awesome-icon :icon="['far', 'heart']" />
                um
                <strong>10 Minuten vor Beginn</strong> der Sendung
                benachrichtigt zu werden.
            </div>
            <div
                v-for="(item, index) in rows"
                :key="index"
                @click="handleClick()"
            >
                <broadcast
                    :layout="BROADCAST_LAYOUT_LIVE"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'

export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            hToday2015: null || localStorage.getItem('hToday2015'),
            selected: 'p2015',
        }
    },
    computed: {
        ...mapGetters('program', {
            p2015: 'p2015',
        }),
        ...mapGetters('program', {
            p2200: 'p2200',
        }),
        ...mapGetters('program', {
            p2300: 'p2300',
        }),
        loading() {
            return !this.$store.state.program.init
        },
        rows() {
            if (this.selected == 'p2015') {
                return this.p2015.filter(item => !item.finished)
            }
            if (this.selected == 'p2200') {
                return this.p2200.filter(item => !item.finished)
            }
            if (this.selected == 'p2300') {
                return this.p2300.filter(item => !item.finished)
            }
            return []
        },
    },
    methods: {
        handleClick() {
            this.hToday2015 = 1
            localStorage.setItem('hToday2015', this.hToday2015)
        },
    },
    mounted() {
        if (
            dayjs().isBetween(
                dayjs()
                    .startOf('day')
                    .add(5, 'hours'),
                dayjs()
                    .startOf('day')
                    .add(23, 'hours')
            )
        ) {
            this.selected = 'p2200'
        }
        if (
            dayjs().isBetween(
                dayjs()
                    .startOf('day')
                    .add(5, 'hours'),
                dayjs()
                    .startOf('day')
                    .add(22, 'hours')
            )
        ) {
            this.selected = 'p2015'
        }

        if (
            dayjs().isAfter(
                dayjs()
                    .startOf('day')
                    .add(23, 'hours')
            )
        ) {
            this.selected = 'p2300'
        }
    },
}
</script>
