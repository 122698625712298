window._ = require('lodash')

import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import axios from 'axios'
import LazyLoadDirective from './directives/LazyLoadDirective'

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.prototype.BROADCAST_LAYOUT_LIVE = 1000
Vue.prototype.BROADCAST_LAYOUT_PROGRAM = 2000
Vue.prototype.BROADCAST_LAYOUT_BOOKMARK = 3000
Vue.prototype.BROADCAST_LAYOUT_SEARCH = 4000
Vue.prototype.BROADCAST_LAYOUT_RECOMMENDATION = 5000
Vue.prototype.BROADCAST_LAYOUT_RECOMMENDATION_FULL = 6000

//import MockDate from 'mockdate'
//MockDate.set('2021-05-11 00:01:01')

import router from './router'
import store from './store'
import {
    BVToastPlugin,
    SpinnerPlugin,
    FormCheckboxPlugin,
    FormSelectPlugin,
    ModalPlugin,
    SkeletonPlugin,
    DropdownPlugin,
    ImagePlugin,
} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faAd,
    faExchangeAlt,
    faArrowsAltV,
    faDotCircle,
    faChevronRight,
    faPlusCircle,
    faExclamationTriangle,
    faCheck,
    faTimes,
    faUserCog,
    faCog,
    faTrashAlt,
    faClock,
    faAngleRight,
    faMedal,
    faPlayCircle,
    faStream,
    faBookmark,
    faBroadcastTower,
    faStar as fasStar,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faMinusCircle,
    faSearch,
    faStarHalfAlt,
    faMobileAlt,
    faHandHoldingHeart,
    faGift,
    faUser,
    faTv,
    faCalendarDay,
    faMagic,
    faCloudMoon,
    faPlus,
    faEllipsisV,
    faWindowRestore,
    faChevronUp,
    faChevronDown,
    faHeart as fasHeart,
    faPlay,
    faFutbol,
    faMicroscope,
    faSortAlphaDown,
    faFilm,
    faImages,
    faNewspaper,
    faChild,
    faMusic,
    faFish,
    faCommentMedical,
    faComments,
    faTheaterMasks,
    faGrinTears,
    faHeartbeat,
    faExpandArrowsAlt,
    faFileVideo,
    faSurprise,
} from '@fortawesome/free-solid-svg-icons'

import {
    faStar as farStar,
    faListAlt,
    faBell,
    faSave,
    faBellSlash,
    faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faAd,
    faExchangeAlt,
    faArrowsAltV,
    faDotCircle,
    faChevronRight,
    faPlusCircle,
    faMinusCircle,
    faExclamationTriangle,
    faCheck,
    faTimes,
    faUserCog,
    faCog,
    faTrashAlt,
    faClock,
    faAngleRight,
    faMedal,
    faPlayCircle,
    faStream,
    faBookmark,
    faBroadcastTower,
    fasStar,
    farStar,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faSearch,
    faStarHalfAlt,
    faMobileAlt,
    faHandHoldingHeart,
    faGift,
    faUser,
    faTv,
    faCalendarDay,
    faListAlt,
    faMagic,
    faCloudMoon,
    faPlus,
    faEllipsisV,
    faBell,
    faSave,
    faBellSlash,
    faChevronUp,
    faChevronDown,
    fasHeart,
    farHeart,
    faPlay,
    faFutbol,
    faMicroscope,
    faSortAlphaDown,
    faFilm,
    faImages,
    faNewspaper,
    faChild,
    faMusic,
    faFish,
    faComments,
    faTheaterMasks,
    faGrinTears,
    faHeartbeat,
    faExpandArrowsAlt,
    faFileVideo,
    faSurprise
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'dayjs/locale/de'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'

dayjs.extend(isBetween)
dayjs.locale('de')
window.dayjs = dayjs

//Vue.config.productionTip = false

Vue.directive('lazyload', LazyLoadDirective)

Vue.use(BVToastPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ModalPlugin)
Vue.use(SkeletonPlugin)
Vue.use(DropdownPlugin)
Vue.use(ImagePlugin)

Vue.mixin({
    methods: {
        nativeBridge(fn, params) {
            if (typeof NativeInterface !== 'undefined') {
                if (typeof params !== 'undefined') {
                    if (typeof params === 'object') {
                        if (fn == 'openUrl') {
                            /* global NativeInterface */
                            return NativeInterface[fn](params.url, params.mode)
                        } else {
                            return NativeInterface[fn](JSON.stringify(params))
                        }
                    } else {
                        return NativeInterface[fn](params)
                    }
                } else {
                    return NativeInterface[fn]()
                }
            } else {
                console.log('NativeInterface not supported.')
                console.log('NativeInterface:', fn, params)
            }
        },
        handleAxiosError(e) {
            console.log(e)
            let errorTitle = ''
            let errorDescription = ''
            if (e.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                //console.log(e.response.data)
                //console.log(e.response.status)
                //console.log("HEADER", e.response.headers)
                errorTitle = 'HTTP-Fehler'
                errorDescription =
                    'Der Server antwortet mit folgendem Status: ' +
                    e.response.status

                axios.get('/log?http_code=' + e.response.status)
            } else if (e.request) {
                /*
                 * The request was made but no response was received, `e.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                //console.log(e.request)
                errorTitle = 'Internetprobleme'
                errorDescription = 'Bitte Internet einschalten'
            } else {
                // Something happened in setting up the request and triggered an e
                //console.log('Error', e.message)
                errorTitle = 'Unbekannter Fehler'
                errorDescription = e.message
            }
            //console.log("config", e.config)

            const h = this.$createElement

            // Create the message
            const vNodesMsg = h('p', { class: ['mb-0'] }, [
                h('strong', {}, errorTitle),
                h('div', {}, errorDescription),
                //h("div", {}, "URL: " + e.config.url),
                //h("div", {}, "Methode: " + e.config.method)
            ])

            // Create the title
            const vNodesTitle = h(
                'div',
                { class: ['d-flex', 'flex-grow-1', 'align-items-center'] },
                [
                    h('font-awesome-icon', {
                        class: ['text-danger', 'mr-2'],
                        props: { icon: 'exclamation-triangle' },
                    }),
                    h('strong', { class: 'mr-2' }, 'Problem aufgetreten'),
                    //h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
                ]
            )

            this.$bvToast.toast([vNodesMsg], {
                toaster: 'b-toaster-bottom-right',
                title: [vNodesTitle],
                //noAutoHide: true
            })
        },
    },
})
new Vue({
    el: '#app',
    render: h => h(App),
    components: { App },
    router,
    store,
})
