<template>
    <div
        class="shadow-sm bg-white d-flex align-items-center"
        :class="{
            'flex-column justify-content-center text-center m-1 p-1':
                layout == 0,
            'my-2 p-2': layout == 1,
        }"
        @click="handleClick()"
    >
        <img v-if="layout == 0" :src="item.image_url" style="max-width: 80px" />
        <div
            v-if="layout == 0"
            class="mt-2"
            style="width: 80px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-weight: bold"
        >
            {{ item.name }}
        </div>

        <div v-if="layout == 1" class="d-flex">
            <div>
                <img :src="item.image_url" width="50px" />
            </div>
            <div class="p-2">
                <h6 class="my-0 page-title">{{ item.name }}</h6>
                <span class="text-muted"> {{ item.item_count }} Videos </span>
            </div>
        </div>
        <div v-if="layout == 1" class="pr-2 flex-fill text-right">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>

        <b-modal
            v-model="modal.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="light"
            @shown="onModalShown"
            @hide="onModalHide"
        >
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div class="p-2 h5 mb-0" @click="modal.show = false">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            <div class="d-flex align-items-center">
                <img :src="item.image_url" style="max-width: 80px" />
                <div class="p-2">
                    <strong>{{ item.name }}</strong>
                    <div v-if="!loading">
                        Alle Videos für den direkten Abruf
                    </div>
                </div>
            </div>
            <div class="w-100 text-right pt-1" v-if="item.type == 'youtube'">
                <img
                    src="../../assets/youtube-developed-with-black.png"
                    width="140px"
                />
            </div>
            <hr />
            <loading v-if="loading" />
            <div v-else>
                <media-library-item
                    v-for="(item, index) in items"
                    :key="index"
                    :index="index"
                    :item="item"
                    :layout="0"
                />
                <loading v-if="appendLoading" />
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MediaLibraryItem from './Item'
import Loading from '../../components/Loading'
import { EventBus } from '@/event-bus'
export default {
    name: 'Channel',
    props: {
        item: {
            type: Object,
            required: true,
        },
        layout: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    components: {
        MediaLibraryItem,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            modal: {
                show: false,
            },
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            items: 'channelItems',
        }),
        loading() {
            return (
                this.$store.state.medialibrary.channelItems.loading &&
                !this.$store.state.medialibrary.channelItems.append
            )
        },
        appendLoading() {
            return (
                this.$store.state.medialibrary.channelItems.loading &&
                this.$store.state.medialibrary.channelItems.append
            )
        },
    },
    methods: {
        handleClick() {
            this.modal.show = true
            this.fetchItems()
        },
        fetchItems(offset) {
            console.log('fetch', offset)
            this.$store
                .dispatch('medialibrary/channelItems', {
                    channelId: this.item.id,
                    offset: offset !== undefined ? offset : 0,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
        onModalBottomed() {
            console.log('onModalBottomed')
            if (this.items.length && !this.appendLoading) {
                this.fetchItems(this.items.length)
            }
        },
        onModalShown() {
            console.log('shown')
            EventBus.$on('modal-bottomed', this.onModalBottomed)
        },
        onModalHide() {
            console.log('hide')
            EventBus.$off('modal-bottomed', this.onModalBottomed)
        },
    },
    mounted() {},
}
</script>
