<template>
    <div
        class="shadow-sm bg-white d-flex align-items-center m-0 my-2 p-2"
        @click="handleClick()"
    >
        <div class="d-flex align-items-center">
            <img :src="item.channel_image_url" width="50px" />
            <div class="pl-2">
                <strong class="d-block text-gray-dark">
                    {{ item.name }}
                </strong>
                <span class="text-muted"
                    >{{ item.item_count }} Videos |
                    {{ item.channel_name }}</span
                >
            </div>
        </div>
        <div class="pr-2 flex-fill text-right">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>

        <b-modal
            v-model="modal.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="light"
        >
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div class="p-2 h5 mb-0" @click="modal.show = false">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>

            <div class="d-flex align-items-center">
                <img :src="item.channel_image_url" style="max-width: 75px" />

                <div class="p-2">
                    <span class="text-muted">{{ item.channel_name }}</span>
                    <h5 class=" font-weight-bold mb-0">
                        {{ item.name }}
                    </h5>
                    {{ seriesItems.length }} Videos vorhanden
                </div>
            </div>
            <hr />
            <loading v-if="modal.loading" />
            <div v-else>
                <media-library-item
                    v-for="(item, index) in seriesItems"
                    :key="'a-' + index"
                    :item="item"
                    :layout="0"
                    :index="index"
                />
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MediaLibraryItem from './Item'
import ImageItem from '../../components/ImageItem'
import Loading from '../../components/Loading'
export default {
    name: 'Series',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    components: {
        MediaLibraryItem,
        ImageItem,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            modal: {
                show: false,
                loading: true,
            },
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            seriesItems: 'seriesItems',
        }),
    },
    methods: {
        handleClick() {
            this.modal.show = true
            this.fetchItems()
        },
        fetchItems() {
            this.modal.loading = true
            this.$store
                .dispatch('medialibrary/seriesItems', {
                    seriesId: this.item.name,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.modal.loading = false
                })
        },
    },
    mounted() {},
}
</script>
