<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="page-title mb-0">Sendungen</h5>
            <div
                v-for="(item, index) in series.filter(
                    (item, index) => index < 20
                )"
                :key="'a-' + index"
            >
                <series :item="item" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Series from '../components/medialibrary/Series'
import Loading from '../components/Loading'
export default {
    components: {
        Series,
        Loading,
    },
    data: function() {
        return {
            dayjs,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            series: 'series',
        }),
        loading() {
            return this.$store.state.medialibrary.series.loading
        },
    },
    methods: {},
    mounted() {},
}
</script>
