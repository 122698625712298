import Vue from 'vue'

const state = {
    search: [],
    all: [],
    first: {},
    broadcasts: [],
    recommendations: [],
    c: 0,
    init: false,
}
const getters = {
    search: (state, getters, rootState, rootGetters) => {
        if (state.search.length) {
            return state.search
        } else return []
    },
    series: (state, getters, rootState, rootGetters) => {
        if (state.all.length) {
            return state.all
        } else return []
    },
    first: (state, getters, rootState, rootGetters) => {
        if (Object.keys(state.first).length) {
            return state.first
        } else return {}
    },
    broadcasts: (state, getters, rootState, rootGetters) => {
        if (Object.keys(state.first).length && state.first.broadcasts.length) {
            return state.first.broadcasts
        } else return []
    },
    recommendations: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = rootState.series.init

        if (state.recommendations.length && seriesInit) {
            return state.recommendations
                .map(item => {
                    item.series = series.find(
                        series => series.series_id == item.series_id
                    )
                    if (item.series === undefined) {
                        item.series = null
                    }

                    item.running = dayjs().isBetween(item.bc_start, item.bc_end)
                    item.c = state.c
                    return item
                })
                .filter(item => dayjs().isBefore(item.bc_end))
        } else return []
    },
    recommendationsGroupedByDate: (state, getters, rootState, rootGetters) => {
        if (getters.recommendations) {
            const groups = getters.recommendations.reduce((groups, item) => {
                const date = dayjs(item.bc_start).format('YYYY-MM-DD')
                if (!groups[date]) {
                    groups[date] = []
                }
                groups[date].push(item)
                return groups
            }, {})

            let groupArrays = Object.keys(groups).map(date => {
                return {
                    date,
                    items: _.sortBy(groups[date], [
                        function(o) {
                            return o.bc_start
                        },
                    ]),
                }
            })

            return _.sortBy(groupArrays, [
                function(o) {
                    return o.date
                },
            ])
        } else return []
    },

    upcomingBroadcasts: (state, getters, rootState, rootGetters) => {
        if (state.broadcasts.length) {
            return state.broadcasts
                .map(item => {
                    item.running = dayjs().isBetween(item.bc_start, item.bc_end)
                    item.c = state.c
                    return item
                })
                .filter(item => dayjs().isBefore(item.bc_end))
        } else return []
    },
    groupedByDate: (state, getters, rootState, rootGetters) => {
        if (getters.upcomingBroadcasts) {
            const groups = getters.upcomingBroadcasts.reduce((groups, item) => {
                const date = dayjs(item.bc_start).format('YYYY-MM-DD')
                if (!groups[date]) {
                    groups[date] = []
                }
                groups[date].push(item)
                return groups
            }, {})

            let groupArrays = Object.keys(groups).map(date => {
                return {
                    date,
                    items: _.sortBy(groups[date], [
                        function(o) {
                            return o.bc_start
                        },
                    ]),
                }
            })

            return _.sortBy(groupArrays, [
                function(o) {
                    return o.date
                },
            ])
        } else return []
    },
}
const actions = {
    async search(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            const query =
                '?api_token=' + token + '&term=' + payload.data.keyword

            axios
                .get('/series/search' + query, {
                    cancelToken: payload.options.cancelToken,
                })
                .then(response => {
                    context.commit('SET_SEARCH_RESULTS', response.data)
                    resolve()
                })
                .catch(e => {
                    if (axios.isCancel(e)) {
                        resolve()
                    } else {
                        reject(e)
                    }
                })
        })
    },
    async show(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            const query = '?api_token=' + token

            axios
                .get('/series/' + payload.data.series_id + query)
                .then(response => {
                    response.data.time_groups = response.data.time_groups.map(
                        item => {
                            return {
                                value: item,
                                loading: false,
                            }
                        }
                    )

                    context.commit('UPDATE_PROP', {
                        prop: 'first',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async update(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/series/' + payload.data.id, payload.data.update, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    const idx = context.state.all.findIndex(
                        item => item.series_id == response.data.series_id
                    )
                    Object.keys(payload.data.update).forEach(item => {
                        Vue.set(
                            context.state.all[idx],
                            item,
                            response.data[item]
                        )
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async broadcasts(context, payload) {
        let query = '?api_token=' + context.rootState.auth.token
        if (payload !== undefined && payload.today) {
            query += '&today'
        }
        return new Promise((resolve, reject) => {
            axios
                .get('/series/broadcasts' + query)
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'broadcasts',
                        value: response.data,
                    })

                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async fetch(context, payload) {
        let query = '?api_token=' + context.rootState.auth.token
        if (payload.broadcasts) {
            query += '&broadcasts=1'
        }

        //const qs = query.forEach(item => item.)
        return new Promise((resolve, reject) => {
            axios
                .get('/series' + query)
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'all',
                        value: response.data,
                    })
                    context.commit('UPDATE_PROP', {
                        prop: 'init',
                        value: true,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async recommendations(context, payload) {
        let query = '?api_token=' + context.rootState.auth.token
        if (payload !== undefined && payload.today) {
            query += '&today'
        }
        if (payload !== undefined && payload.date && payload.date !== null) {
            query += '&date=' + payload.date
        }
        return new Promise((resolve, reject) => {
            axios
                .get('/recommendations' + query)
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'recommendations',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    sync(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .post('/series', payload.data, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'all',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async destroy(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .delete('/bookmarks/' + payload.data.id, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('REMOVE_SERIES', payload.data)
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}
const mutations = {
    SET_SEARCH_RESULTS(state, search) {
        Vue.set(state, 'search', search)
    },
    SET_SERIES(state, series) {
        Vue.set(state, 'all', series)
    },
    REMOVE_SERIES(state, series) {
        state.all.splice(state.all.indexOf(series), 1)
    },
    UPDATE_PROP(state, payload) {
        state[payload.prop] = payload.value
    },
    INCREMENT_COUNTER(state) {
        Vue.set(state, 'c', state.c + 1)
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
