<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-if="anyDataReady">
            <h5 class="font-weight-bold my-0 mb-1">22:00 im TV</h5>
            <p
                class="text-muted mb-0"
                v-if="
                    p2015Description === null &&
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(21, 'hours')
                                .add(50, 'minutes')
                        )
                "
            >
                Tippe auf einen Eintrag um die Sendung für später zu merken
            </p>
            <div
                v-for="(item, index) in p2200"
                :key="index"
                @click="handleClick()"
            >
                <broadcast
                    :layout="BROADCAST_LAYOUT_LIVE"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {
            dayjs,
            p2015Description: null || localStorage.getItem('p2015d'),
        }
    },
    computed: {
        ...mapGetters('program', {
            p2200: 'p2200',
        }),
        anyDataReady() {
            return this.$store.state.program.init
        },
    },
    methods: {
        handleClick() {
            this.p2015Description = 1
            localStorage.setItem('p2015d', this.p2015Description)
        },
    },
    created() {},
}
</script>
