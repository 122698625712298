var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[(!_vm.historyBack)?_c('div',{staticClass:"w-100 container-fluid",staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"1001"}},[_c('div',{staticClass:"row align-items-center py-2 bg-light"},[_vm._m(0),_c('div',{staticClass:"col-6 text-right settings"},[_c('router-link',{ref:"search",staticClass:"text-dark mr-4",attrs:{"to":{
                        name: 'search',
                        query: {
                            only_search: 1,
                            force_update: _vm.$route.query.force_update,
                        },
                    }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'search']}})],1),_c('router-link',{ref:"settings",staticClass:"text-dark",attrs:{"to":{
                        name: 'settings',
                        query: {
                            date: _vm.$route.query.date,
                            history_back: 1,
                            force_update: _vm.$route.query.force_update,
                        },
                    }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cog']}})],1)],1)]),_c('div',{staticClass:"row text-white",staticStyle:{"background":"#000"}},[_c('div',{staticClass:"col-12 n d-flex flex-nowrap justify-content-around"},[_c('router-link',{ref:"today",staticClass:"d-flex flex-column align-items-center",attrs:{"to":{ name: 'today' }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'calendar-day']}}),_c('span',[_vm._v("Heute")])],1),_c('router-link',{ref:"program",staticClass:"d-flex flex-column align-items-center",attrs:{"to":{ name: 'program' }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'list-alt']}}),_c('span',[_vm._v("Programm")])],1),_c('router-link',{ref:"medialibrary",staticClass:"d-flex flex-column align-items-center",attrs:{"to":{ name: 'medialibrary' }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tv']}}),_c('span',[_vm._v("Mediathek")])],1),_c('router-link',{ref:"mytv",staticClass:"d-flex flex-column align-items-center",attrs:{"to":{ name: 'tv' }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'user']}}),_c('span',[_vm._v("Mein TV")])],1)],1)])]):_vm._e(),(_vm.historyBack)?_c('div',{staticClass:"w-100 container-fluid",staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"1001"}},[_c('div',{staticClass:"row text-white",staticStyle:{"background":"#000"}},[_c('div',{staticClass:"col-12 d-flex"},[_c('div',{staticClass:"px-2 py-4 h5 mb-0",on:{"click":function($event){return _vm.back()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-left']}})],1)])])]):_vm._e(),_c('div',{staticClass:"container-fluid pt-2",style:([
            _vm.historyBack
                ? { 'margin-top': '70px' }
                : { 'margin-top': '103px' } ])},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 brand d-flex align-items-center"},[_c('img',{staticClass:"mr-1",attrs:{"src":"https://lh3.googleusercontent.com/8F5m8o-6J77JGLEOic9fe7m9MM2I2zozyU_CfXALeQKlIg0z8sKVeiN8oc6aPW-NIJA=s180-rw","height":"20px"}}),_c('span',{staticStyle:{"line-height":"20px"}},[_vm._v("LIVE TV")])])}]

export { render, staticRenderFns }