var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.anyDataReady)?_c('div',[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}}),_c('b-skeleton',{attrs:{"width":"45%"}})],1):_vm._e(),(_vm.anyDataReady)?_c('div',[_c('h5',{staticClass:"font-weight-bold my-0 mb-1"},[_vm._v("22:00 im TV")]),(
                _vm.p2015Description === null &&
                    _vm.dayjs().isBefore(
                        _vm.dayjs()
                            .startOf('day')
                            .add(21, 'hours')
                            .add(50, 'minutes')
                    )
            )?_c('p',{staticClass:"text-muted mb-0"},[_vm._v(" Tippe auf einen Eintrag um die Sendung für später zu merken ")]):_vm._e(),_vm._l((_vm.p2200),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleClick()}}},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_LIVE,"item":item,"c":item.c}})],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }