<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-if="anyDataReady">
            <h5 class="font-weight-bold my-0 mb-1">Film Empfehlungen</h5>
            <div class="alert alert-primary" v-if="hTvMovies === null">
                Hier findest du TOP Filme der nächsten 7 Tage. Tippe auf
                <font-awesome-icon :icon="['far', 'heart']" />
                um
                <strong>10 Minuten vor Beginn</strong> des Films benachrichtigt
                zu werden.
            </div>
            <div class="days d-flex py-2">
                <div
                    v-for="(item, index) in days"
                    :key="'date-' + index"
                    class="shadow-sm py-1 px-3 mx-1 bg-white text-center"
                    :class="{
                        'text-primary': item.isToday,
                        'border border-2 border-primary':
                            item.format('DD-MM-YYYY') == day ||
                            (day === null && index == 0),
                    }"
                    @click="navigateDate(item)"
                >
                    <small class="text-muted">
                        <span
                            :class="{
                                'text-danger':
                                    item.format('dd') == 'Sa' ||
                                    item.format('dd') == 'So',
                            }"
                            >{{ item.format('dd') }}</span
                        >
                    </small>
                    <br />
                    <h4 class="m-0 font-weight-bolder">
                        {{ item.format('D') }}
                    </h4>
                    <small
                        v-if="item.isSame(new Date(), 'day')"
                        style="margin-top: -2px;display: block"
                        >heute</small
                    >
                </div>
            </div>
            <div v-for="(item, index) in future" :key="'f-' + index">
                <!--
                <h5
                    class="font-weight-bold mt-3 mb-1"
                    v-if="dayjs(item.date).isSame(new Date(), 'day')"
                >
                    Heute ({{ dayjs(item.date).format('DD.MM.YYYY') }})
                </h5>
                
                <h5
                    class="font-weight-bold mt-3 mb-1"
                    v-if="!dayjs(item.date).isSame(new Date(), 'day')"
                >
                    {{ dayjs(item.date).format('dd, DD.MM.YYYY') }}
                </h5>
                -->
                <broadcast
                    v-for="(item2, index2) in item.items"
                    :key="'f2' + index2"
                    :layout="BROADCAST_LAYOUT_RECOMMENDATION_FULL"
                    :item="item2"
                    :c="item2.c"
                    imdb
                    @click=""
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {
            dayjs,
            day: null,
            days: [...Array(7).keys()].map(i => {
                return dayjs()
                    .startOf('day')
                    .add(i, 'days')
            }),
            loading: false,
            show: false,
            hTvMovies: null || localStorage.getItem('hTvMovies'),
        }
    },
    computed: {
        ...mapGetters('series', {
            future: 'recommendationsGroupedByDate',
        }),
        anyDataReady() {
            return (
                this.$store.state.program.init &&
                this.$store.state.series.init &&
                this.loading === false
            )
        },
    },
    methods: {
        handleBroadcastClick() {
            this.hTvMovies = 1
            localStorage.setItem('hTvMovies', this.hTvMovies)
        },
        navigateDate(item) {
            this.day = item.format('DD-MM-YYYY')
            this.fetchRecommendations()
        },
        fetchRecommendations() {
            this.loading = true
            this.$store
                .dispatch('series/recommendations', {
                    date: this.day,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        this.fetchRecommendations()
    },
}
</script>
