<template>
    <div>
        <loading v-if="!anyDataReady" />
        <div v-else>
            <div
                class="bg-white shadow-sm p-3 rating"
                v-if="banner.rating.show"
            >
                <h1 class="h4 font-weight-bold mb-2">Gefällt dir die App?</h1>
                <div class="d-flex">
                    <button
                        type="button"
                        class="btn btn-outline-primary mr-2"
                        style="width: 120px"
                        @click="bannerRatingActionA()"
                    >
                        Ja
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        style="width: 120px"
                        @click="bannerRatingActionB()"
                    >
                        Nein
                    </button>
                </div>
            </div>
            <div
                class="alert alert-dismissible shadow-sm bg-white my-3"
                v-if="banner.rating.action.a"
            >
                <button
                    type="button"
                    class="close"
                    @click="bannerRatingActionClose()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="h4 font-weight-bold mb-2">
                    <font-awesome-icon
                        :icon="['fas', 'star-half-alt']"
                        class="mr-1"
                    />
                    Bitte bewerte uns
                </h1>
                <p class="mb-1">
                    Wir würden uns sehr freuen wenn du dir kurz Zeit nimmst und
                    uns 5 Sterne in Google Play geben würdest.
                </p>
                <button
                    type="button"
                    class="btn btn-block btn-outline-primary"
                    @click="bannerRatingActionAA()"
                >
                    Jetzt Bewertung abgeben
                </button>
            </div>
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('live')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'play-circle']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Jetzt im TV</h1>
                    <span class="text-muted mb-0">Was läuft gerade?</span>
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('p2015')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'magic']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Heute Abend</h1>
                    <span class="text-muted mb-0">Was läuft ab 20:15?</span>
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('recommendations')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'star']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">TOP Filme</h1>
                    <span class="text-muted mb-0"
                        >TV Filme der nächsten 7 Tage</span
                    >
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div v-if="todaysBroadcasts.length">
                <h1
                    class="h5 font-weight-bold mt-4 mb-2"
                    @click="cc('bookmarks')"
                >
                    Mein Programm
                    <font-awesome-icon
                        :icon="['fas', 'chevron-right']"
                        class="ml-2 small"
                    />
                </h1>
                <div v-if="loading">
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="45%"></b-skeleton>
                </div>
                <div v-else>
                    <broadcast
                        v-for="(item, index) in todaysBroadcasts"
                        :key="'a-' + index"
                        :layout="BROADCAST_LAYOUT_BOOKMARK"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
            <div>
                <h1
                    class="h5 font-weight-bold mt-4 mb-2"
                    @click="cc('medialibrary-movies')"
                >
                    Filme aus der Mediathek
                    <font-awesome-icon
                        :icon="['fas', 'chevron-right']"
                        class="ml-2 small"
                    />
                </h1>
                <div
                    class="alert alert-primary mb-1"
                    v-if="hMediaLibraryMovies === null"
                >
                    Alle Filme aus der Mediathek können zu
                    <strong>jeder Zeit</strong> abgespielt werden. Es kommen
                    jeden Tag neue Filme hinzu.
                </div>
                <loading v-if="loading" />
                <div v-else class="slider" @click="hideHint">
                    <media-library-item
                        v-for="(item, index) in movies"
                        :key="'a1-' + index"
                        :index="index"
                        :item="item"
                        :layout="2"
                    />
                    <div
                        class="shadow-sm bg-white m-1 p-3 d-flex flex-column justify-content-center align-items-center"
                        @click="to('medialibrary-movies')"
                    >
                        <span
                            class="h5 font-weight-bold mb-0"
                            style="min-width: 100px"
                        >
                            Alle <br />anzeigen
                            <font-awesome-icon
                                :icon="['fas', 'chevron-right']"
                                class="ml-1"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import axios from 'axios'
import Loading from '../components/Loading'
import MediaLibraryItem from '../components/medialibrary/Item'

export default {
    components: {
        Broadcast,
        Loading,
        MediaLibraryItem,
    },
    data: function() {
        return {
            hMediaLibraryMovies:
                null || localStorage.getItem('hMediaLibraryMovies'),
            dayjs,
            banner: {
                primeVideo: {
                    show: false,
                    action: {
                        a: false,
                    },
                },
                rating: {
                    show: false,
                    action: {
                        a: false,
                        b: false,
                    },
                },
                dkms: {
                    show: false,
                    action: {
                        a: false,
                    },
                },
            },
            loading: true,
        }
    },
    computed: {
        ...mapGetters('series', {
            broadcasts: 'upcomingBroadcasts',
        }),
        ...mapGetters('series', {
            recommendations: 'recommendations',
        }),
        ...mapGetters('medialibrary', {
            movies: 'movies',
        }),
        anyDataReady() {
            return (
                this.$store.state.program.init && this.$store.state.series.init
            )
        },
        todaysBroadcasts() {
            return this.broadcasts.filter(item =>
                dayjs(item.bc_start).isSame(new Date(), 'day')
            )
        },
    },
    methods: {
        hideHint() {
            this.hMediaLibraryMovies = 1
            localStorage.setItem(
                'hMediaLibraryMovies',
                this.hMediaLibraryMovies
            )
        },
        fetchBroadcasts() {
            this.loading = true
            this.$store
                .dispatch('series/broadcasts', { today: true })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
        fetchMovies() {
            this.loading = true
            this.$store
                .dispatch('medialibrary/movies')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
        fetchRecommendations() {
            this.loading = true
            this.$store
                .dispatch('series/recommendations', { today: true })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
        cc(val) {
            if (
                dayjs().isBetween(
                    dayjs()
                        .startOf('day')
                        .add(1, 'hours'),
                    dayjs()
                        .startOf('day')
                        .add(5, 'hours')
                )
            ) {
                val = 'program'
            }

            this.$router.push({
                name: val,
                query: {
                    history_back: 1,
                },
            })
        },
        bannerDkmsActionClose() {
            this.banner.dkms.show = false
            localStorage.setItem('banner-dkms-hide', true)
            axios
                .post(
                    '/activity/banner/dkms',
                    { v: 'close' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        bannerDkmsActionA() {
            this.banner.dkms.show = false
            this.banner.dkms.action.a = true
            axios
                .post(
                    '/activity/banner/dkms',
                    { v: 'click' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
            this.nativeBridge('openUrl', {
                url: 'https://www.dkms.de/de/spender-werden',
                mode: 'chrome',
            })
        },

        bannerPrimeVideoActionA() {
            this.banner.primeVideo.action.a = true

            axios
                .post(
                    '/activity/banner/prime-video',
                    { v: 'click' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
            this.nativeBridge('openUrl', {
                url: 'http://www.amazon.de/gp/video/primesignup?tag=gagatv-21',
                mode: 'chrome',
            })
        },
        bannerPrimeVideoActionClose() {
            this.banner.primeVideo.show = false
            localStorage.setItem('banner-prime-video-hide', true)
            axios
                .post(
                    '/activity/banner/prime-video',
                    { v: 'close' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },

        bannerRatingActionA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = true
        },
        bannerRatingActionB() {
            this.banner.rating.show = false
            this.banner.rating.action.b = true
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionClose() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionAA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
            this.nativeBridge('openPlayStore', 'com.live.tv.kostenlos')
        },
    },
    mounted() {
        this.fetchMovies()
        //this.fetchBroadcasts()
        this.fetchRecommendations()
        if (localStorage.getItem('banner-rating-hide') == null) {
            this.banner.rating.show = true
        } else {
            if (
                localStorage.getItem('banner-prime-video-hide') == null &&
                localStorage.getItem('app_version') == 6
            ) {
                //this.banner.primeVideo.show = true
            } else {
                if (localStorage.getItem('banner-dkms-hide') == null) {
                    //this.banner.dkms.show = true
                }
            }
        }
    },
}
</script>
