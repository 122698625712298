<template>
    <div>
        <loading v-if="!anyDataReady" />
        <div v-else>
            <h5 class="font-weight-bold my-0 mb-1">
                Jetzt LIVE im TV
            </h5>
            <div class="alert alert-primary" v-if="hTodayLive === null">
                Tippe auf
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                um den Livestream zu starten.
            </div>
            <div
                v-for="(item, index) in pNow"
                :key="index"
                @click="handleClick()"
            >
                <broadcast
                    :layout="BROADCAST_LAYOUT_LIVE"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'

export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function() {
        return {
            hTodayLive: null || localStorage.getItem('hTodayLive'),
        }
    },
    computed: {
        ...mapGetters('program', {
            pNow: 'pNow',
        }),
        anyDataReady() {
            return this.$store.state.program.init
        },
    },
    methods: {
        handleClick() {
            this.hTodayLive = 1
            localStorage.setItem('hTodayLive', this.hTodayLive)
        },
    },
}
</script>
