<template>
    <div
        class="d-flex shadow-sm bg-white"
        :class="{
            'm-0 my-2 ': layout == 0 || layout == 3,
            'm-1': layout == 1 || layout == 2,
        }"
        @click="handleClick(item)"
    >
        <div class="w-100" v-if="layout == 0">
            <image-item :index="index" :source="item.image_url" class="w-100" />
            <div class="p-2">
                <h6 class="my-0 page-title">
                    {{ item.title }}
                    <span v-if="item.series_name != null">
                        - {{ item.series_name }}</span
                    >
                    | {{ item.station }}
                </h6>
                <span class="text-muted"
                    >vom {{ dayjs(item.published_at).format('DD.MM.YYYY') }} |
                    {{ item.duration }} Minuten</span
                >
            </div>
        </div>

        <div class="w-100" v-if="layout == 3">
            <image-item :index="index" :source="item.image_url" class="w-100" />
            <div class="p-2">
                <h6 class="my-0 page-title">
                    {{ item.title }}
                    <span v-if="item.series_name != null">
                        - {{ item.series_name }}</span
                    >
                </h6>
                <span class="text-muted">
                    {{ item.genres.split(',').join(', ') }} |
                    {{ item.duration }} Minuten
                </span>
            </div>
        </div>

        <div style="width: 180px" v-if="layout == 1">
            <image-item :index="index" :source="item.image_url" class="w-100" />
            <div class="p-2">
                <h6 class="my-0 page-title lines-4">{{ item.title }}</h6>
                <span class="text-muted"> {{ item.duration }} Minuten</span>
            </div>
        </div>
        <div style="width: 180px" v-if="layout == 2">
            <image-item
                :index="index"
                :source="item.image_url"
                class="w-100"
                height="250px"
            />
            <div class="p-2">
                <h6 class="my-0 page-title lines-4">{{ item.title }}</h6>
                <span class="text-muted">
                    {{ item.duration }} Minuten <br />
                    <small v-if="item.genres">{{
                        item.genres.split(',').join(', ')
                    }}</small></span
                >
            </div>
        </div>
        <ad v-if="ad.show" :response="ad.response" />
    </div>
</template>
<script>
import ImageItem from '../../components/ImageItem'
import Ad from '../../components/Ad'
export default {
    name: 'Item',
    props: ['item', 'layout', 'index'],
    components: {
        ImageItem,
        Ad,
    },
    data: function() {
        return {
            dayjs,
            ad: {
                show: false,
                response: {},
            },
        }
    },
    computed: {},
    methods: {
        handleClick() {
            const token = this.$store.state.auth.token

            axios
                .post(
                    '/activity/start-media-library-item',
                    {
                        id: this.item.id,
                        ad_available:
                            localStorage.getItem('ad_available') == 'true',
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then(response => {
                    if (response.data['params'].show_ad) {
                        localStorage.setItem('last_ad_at', dayjs())
                        localStorage.setItem('ad_loaded', 0)
                        this.ad.show = true
                        this.ad.response = response
                    } else {
                        this.nativeBridge(
                            response.data['fn'],
                            response.data['params']
                        )
                    }
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {},
}
</script>
