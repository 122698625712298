<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-else>
            <div class="input-group my-2">
                <input
                    ref="search"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Durchsuchen..."
                    @keyup="search.keyword = $event.target.value"
                    :value="search.keyword"
                />
                <div
                    class="input-group-append"
                    v-if="search.keyword.length > 0"
                >
                    <button
                        class="btn btn-secondary"
                        type="button"
                        @click="search.keyword = ''"
                    >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </div>
            <div v-if="search.loading">
                <b-spinner class="my-4 mx-2" label="Laden..."></b-spinner>
            </div>
            <div v-if="!search.loading && search.keyword.length >= 1">
                <div class="text-left" v-if="search.keyword.length >= 1">
                    <span v-if="searchResults.length == 0"
                        >Keine Ergebnisse</span
                    >
                    <span v-if="searchResults.length == 1">1 Ergebnis</span>
                    <span
                        v-if="
                            searchResults.length >= 2 &&
                                searchResults.length < 49
                        "
                        >{{ searchResults.length }} Ergebnisse</span
                    >
                    <span v-if="searchResults.length == 50"
                        >über {{ searchResults.length }} Ergebnisse</span
                    >
                </div>

                <div v-for="(item, index) in searchResults" :key="'s-' + index">
                    <broadcast :layout="BROADCAST_LAYOUT_SEARCH" :item="item" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {
            loading: false,
            search: {
                keyword: '',
                items: [],
                loading: false,
                request: null,
            },
            selectedStation: null,
            selectedDate: null,
        }
    },
    watch: {
        'search.keyword': {
            handler() {
                if (this.search.loading) this.cancelRequest()
                if (this.search.keyword.length) {
                    this.search.loading = true
                    this.debSearch()
                } else {
                    this.$store.commit('program/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
        $route: {
            handler() {
                //this.search.keyword = ''
            },
        },
    },
    computed: {
        ...mapGetters('program', {
            searchResults: 'search',
        }),
        anyDataReady() {
            return (
                this.$store.state.program.init && this.$store.state.series.init
            )
        },
    },
    methods: {
        onKeyUp() {
            if (this.search.loading) this.cancelRequest()
            if (this.search.keyword.length >= 2) {
                this.debSearch()
            } else {
                this.searchResults = []
            }
        },
        cancelRequest() {
            this.search.request.cancel()
        },
        searchProgram() {
            const axiosSource = axios.CancelToken.source()
            this.search.request = { cancel: axiosSource.cancel }
            this.search.loading = true
            this.$store
                .dispatch('program/search', {
                    data: {
                        keyword: this.search.keyword,
                    },
                    options: {
                        cancelToken: axiosSource.token,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.search.loading = false
                })
        },
    },
    mounted() {
        this.$refs.search.focus()
    },
    created() {
        window.onscroll = () => {
            let header = document.getElementById('stations')
            if (header) {
                let sticky = header.offsetTop
                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky')
                } else {
                    header.classList.remove('sticky')
                }
            }
        }
        this.debSearch = _.debounce(this.searchProgram, 250)
    },
}
</script>
