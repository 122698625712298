<template>
    <div>
        <div class="d-flex align-items-center justify-content-between mt-4">
            <div @click="wifi_only = !wifi_only">
                <h5 class="font-weight-bold mb-0">Nur über WLAN streamen</h5>
                <small class="text-muted"
                    >Damit nicht dein mobiles Datenvolumen verbraucht
                    wird</small
                >
            </div>
            <div class="pl-4">
                <b-form-checkbox
                    v-model="wifi_only"
                    switch
                    size="lg"
                ></b-form-checkbox>
            </div>
        </div>
        <!--
        <hr class="my-3" />
        <div class="d-flex align-items-center justify-content-between">
            <div @click="notification.muted = !notification.muted">
                <h5 class="font-weight-bold mb-0">
                    Benachrichtigungston
                </h5>
                <small class="text-muted"
                    >Schalte deine Benachrichtigungen stumm</small
                >
            </div>
            <div class="pl-4">
                <b-form-checkbox
                    v-model="notification.muted"
                    switch
                    size="lg"
                ></b-form-checkbox>
            </div>
        </div>
        -->
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.report.show = true"
        >
            <h5 class="font-weight-bold mb-0">Problem melden</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.suggestion.show = true"
        >
            <h5 class="font-weight-bold mb-0">Sender vorschlagen</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.about.show = true"
        >
            <h5 class="font-weight-bold mb-0">Über Uns</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <b-modal
            v-model="modals.report.show"
            centered
            title="Ein Problem melden"
            cancel-title="Abbrechen"
            ok-title="Problem melden"
            @shown="focusInput"
            @ok="onSubmitReport"
        >
            <form>
                <div class="alert alert-success" v-if="modals.report.success">
                    <strong>Vielen Dank für dein Feedback!</strong><br />Wir
                    kümmern uns schnellstmöglich um das Problem. Falls du eine
                    E-Mail angegeben hast melden wir uns bei dir!
                </div>
                <div class="alert alert-danger" v-if="modals.report.error">
                    <strong>Hoppla!</strong><br />Beim Absenden des Formulars
                    ist ein Fehler aufgetreten. Bitte probiere es in Kürze
                    erneut.
                </div>
                <div class="form-group">
                    <label for="email" class="mb-0 font-weight-bold"
                        >E-Mail
                        <small
                            >(nicht nötig, aber nur so können wir dir
                            antworten)</small
                        ></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="$v.modals.report.form.email.$model"
                        placeholder="Eingeben..."
                        :class="{ 'is-invalid': !validateState('email') }"
                    />
                </div>
                <div class="form-group">
                    <label for="message" class="mb-0 font-weight-bold"
                        >Nachricht <small>(nicht nötig)</small></label
                    >
                    <textarea
                        class="form-control"
                        name="message"
                        ref="focusThis"
                        id="message"
                        placeholder="Eingeben..."
                        rows="4"
                        v-model="modals.report.form.message"
                    ></textarea>
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="modals.suggestion.show"
            centered
            title="Verbesserungen vorschlagen"
            cancel-title="Abbrechen"
            ok-title="Vorschlag absenden"
            @shown="focusInput2"
            @ok="onSubmitSuggestion"
        >
            <form>
                <div
                    class="alert alert-success"
                    v-if="modals.suggestion.success"
                >
                    <strong>Vielen Dank für dein Feedback!</strong><br />Wir
                    kümmern uns schnellstmöglich um das Problem. Falls du eine
                    E-Mail angegeben hast melden wir uns bei dir!
                </div>
                <div class="alert alert-danger" v-if="modals.suggestion.error">
                    <strong>Hoppla!</strong><br />Beim Absenden des Formulars
                    ist ein Fehler aufgetreten. Bitte probiere es in Kürze
                    erneut.
                </div>
                <div class="form-group">
                    <label for="email" class="mb-0 font-weight-bold"
                        >E-Mail
                        <small
                            >(nicht nötig, aber nur so können wir dir
                            antworten)</small
                        ></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="$v.modals.suggestion.form.email.$model"
                        placeholder="Eingeben..."
                        :class="{ 'is-invalid': !validateState2('email') }"
                    />
                </div>
                <div class="form-group">
                    <label for="message" class="mb-0 font-weight-bold"
                        >Nachricht <small>(nicht nötig)</small></label
                    >
                    <textarea
                        class="form-control"
                        name="message"
                        ref="focusThis2"
                        id="message"
                        placeholder="Eingeben..."
                        rows="4"
                        v-model="modals.suggestion.form.message"
                    ></textarea>
                </div>
            </form>
        </b-modal>
        <b-modal
            v-model="modals.about.show"
            centered
            title="Über diese App"
            hide-footer
        >
            <strong>Name</strong><br />
            Deutsches Fernsehen - LIVE TV kostenlos gucken<br /><br />
            <strong>Email</strong><br />
            support@vanced-app.com
            <br /><br />
            <strong>Webseite</strong><br />
            <a href="http://www.vanced-app.com">http://www.vanced-app.com</a>
            <br /><br />
            <strong>Google Play</strong><br />
            <a
                href="https://play.google.com/store/apps/details?id=com.tv.live.deutsches.fernsehen"
                >https://play.google.com/store/apps/details?id=com.tv.live.deutsches.fernsehen</a
            >
            <br /><br />
            <strong>Privacy Policy</strong><br />
            <a href="http://www.vanced-app.com/privacy-policy.php"
                >http://www.vanced-app.com/privacy-policy.php</a
            >
            <br /><br />
            <strong>Version</strong><br />
            {{ version }}
        </b-modal>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
export default {
    mixins: [validationMixin],
    data: function() {
        return {
            version: localStorage.getItem('app_version') || '-',
            loading: true,
            notification: {
                muted: false,
                distance: 5,
            },
            mute: {
                from: '00:00',
                to: '06:00',
                labels: {
                    de: {
                        labelHours: 'Stunden',
                        labelMinutes: 'Minuten',
                        labelSeconds: 'Sekunden',
                        labelIncrement: 'Erhöhen',
                        labelDecrement: 'Verringern',
                        labelSelected: 'Ausgewählte Zeit',
                        labelNoTimeSelected: 'Keine Zeit ausgewählt',
                        labelCloseButton: 'Schließen',
                    },
                },
            },
            modals: {
                about: {
                    show: false,
                    data: [],
                },
                report: {
                    success: null,
                    error: null,
                    show: false,
                    form: {
                        email: '',
                        message: '',
                    },
                },
                suggestion: {
                    success: null,
                    error: null,
                    show: false,
                    form: {
                        email: '',
                        message: '',
                    },
                },
            },
        }
    },
    computed: {
        wifi_only: {
            get: function() {
                return this.$store.state.user.settings.wifi_only
            },
            set: function(newValue) {
                this.loading = true
                this.nativeBridge('onlyWifi', newValue)
                this.$store.state.user.settings.wifi_only = newValue
                this.updateSettings()
            },
        },
    },
    watch: {},
    validations: {
        modals: {
            report: {
                form: {
                    email: {
                        email,
                    },
                },
            },
            suggestion: {
                form: {
                    email: {
                        email,
                    },
                },
            },
        },
    },
    methods: {
        updateSettings() {
            this.$store
                .dispatch('user/updateSettings')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                    this.nativeBridge('showToast', 'Einstellungen gespeichert')
                })
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.modals.report.form[name]
            return !$error
            return $dirty ? !$error : null
        },
        validateState2(name) {
            const { $dirty, $error } = this.$v.modals.suggestion.form[name]
            return !$error
            return $dirty ? !$error : null
        },
        focusInput() {
            this.$refs.focusThis.focus()
        },
        focusInput2() {
            this.$refs.focusThis2.focus()
        },
        send() {
            alert('sending...')
        },
        onSubmitReport(e) {
            e.preventDefault()
            this.$v.modals.report.form.$touch()
            if (this.$v.modals.report.form.$anyError) {
                return
            }
            this.modals.report.success = false
            this.modals.report.error = false
            const token = this.$store.state.auth.token
            axios
                .post('/report', this.modals.report.form, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                    this.modals.report.error = true
                })
                .then(response => {
                    this.modals.report.success = true
                    this.modals.suggestion.form = {
                        email: '',
                        message: '',
                    }
                })
        },
        onSubmitSuggestion(e) {
            e.preventDefault()
            this.$v.modals.suggestion.form.$touch()
            if (this.$v.modals.suggestion.form.$anyError) {
                return
            }
            this.modals.suggestion.success = false
            this.modals.suggestion.error = false
            const token = this.$store.state.auth.token
            axios
                .post('/suggestion', this.modals.suggestion.form, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                    this.modals.suggestion.error = true
                })
                .then(response => {
                    this.modals.suggestion.success = true
                    this.modals.suggestion.form = {
                        email: '',
                        message: '',
                    }
                })
        },
    },
    created() {},
}
</script>
