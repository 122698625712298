<template>
    <b-modal
        v-model="modal.show"
        @hidden="adModalHidden()"
        @shown="adCountDownTimer()"
        no-close-on-backdrop
        centered
        modal-class="modal-fullscreen"
        body-class="d-flex align-items-center justify-content-center"
        hide-header
        hide-footer
        no-fade
    >
        <div class="text-center">
            <font-awesome-icon style="font-size: 50px" :icon="['fas', 'ad']" />
            <h2>Werbung beginnt in</h2>
            <h2>{{ countDown }}s</h2>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'Ad',
    props: {
        response: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            modal: {
                show: true,
            },
            countDown: 3,
        }
    },
    computed: {},
    methods: {
        adModalHidden() {
            this.nativeBridge(
                this.response.data['fn'],
                this.response.data['params']
            )
        },
        adCountDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.adCountDownTimer()
                }, 1000)
            } else {
                this.modal.show = false
                setTimeout(() => {
                    this.countDown = 3
                }, 500)
            }
        },
    },
}
</script>
