<template>
    <div>
        <h5 class="page-title mb-0">Alle Filme</h5>
        <loading v-if="loading" />
        <div v-else>
            <b-form-select
                class="mt-2 mb-1"
                v-model="filter.selected"
                :options="filter.options"
                @change="onChangeFilter"
            ></b-form-select>
            <div v-for="(item, index) in items" :key="'a-' + index">
                <media-library-item :item="item" :layout="3" :index="index" />
            </div>
            <loading class="mt-2" v-if="appendLoading" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MediaLibraryItem from '../components/medialibrary/Item'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'

export default {
    components: {
        MediaLibraryItem,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            filter: {
                selected: 'latest',
                options: [
                    { value: 'latest', text: 'Neuste Filme' },
                    {
                        value: 'best_rating_7days',
                        text: 'TOP Filme der letzten 7 Tage',
                    },
                    { value: 'best_rating', text: 'TOP Filme von allen' },
                ],
            },
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            items: 'movies',
        }),
        loading() {
            return (
                this.$store.state.medialibrary.movies.loading &&
                !this.$store.state.medialibrary.movies.append
            )
        },
        appendLoading() {
            return (
                this.$store.state.medialibrary.movies.loading &&
                this.$store.state.medialibrary.movies.append
            )
        },
    },
    methods: {
        onChangeFilter() {
            this.fetchItems(0)
        },
        fetchItems(offset) {
            this.$store
                .dispatch('medialibrary/movies', {
                    offset: offset !== undefined ? offset : 0,
                    sortBy: this.filter.selected,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
        onBottomed() {
            if (this.items.length) {
                this.fetchItems(this.items.length)
            }
        },
    },
    mounted() {
        EventBus.$on('bottomed', this.onBottomed)
        if (!this.items.length && !this.appendLoading) {
            this.fetchItems()
        }
    },
    beforeDestroy() {
        EventBus.$off('bottomed', this.onBottomed)
    },
}
</script>
