<template>
    <figure class="image__wrapper">
        <!-- v-lazyload  -->
        <div class="watermark" v-if="isYoutubeThumbnail">
            <img src="../assets/youtube-logo-white.png" width="100%" />
        </div>

        <b-img-lazy
            v-bind="mainProps"
            :show="index < 10"
            :src="finalSource"
            class="image__item"
            :style="{ height: height }"
        />
    </figure>
</template>

<script>
//import ImageSpinner from './ImageSpinner'

export default {
    name: 'ImageItem',
    components: {
        //ImageSpinner,
    },
    props: {
        height: {
            type: String,
            required: false,
            default: 'auto',
        },
        index: {
            type: Number,
            required: true,
        },
        source: {
            //type: String,
            required: true,
        },
    },
    data() {
        return {
            mainProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb',
                width: 600,
                height: 400,
            },
        }
    },
    computed: {
        finalSource() {
            if (this.source != null) {
                return this.source
            } else {
                return 'https://www.jomabe.de/placeholder-image.webp'
            }
        },
        isYoutubeThumbnail() {
            const re = /ytimg\.com/gm
            const m = re.exec(this.source)
            return m === null ? false : true
        },
    },
}
</script>

<style scoped lang="scss">
.watermark {
    position: absolute;
    bottom: 10px;
    right: 6px;
    z-index: 11;
    width: 33%;
}
.image {
    &__wrapper {
        border-radius: 4px;
        margin-bottom: 0;
        margin: 0 !important;
        width: 100%;
        position: relative;
    }

    &__item {
        width: 100%;
        height: auto;
        position: relative;
        text-indent: -999em;
        line-height: 0;
        object-fit: cover;
        &::after {
            content: '';
            display: block;
            background-color: white;
            background-image: url('https://www.jomabe.de/placeholder-image.webp');
            background-position: center;
            background-size: cover;
            padding: 33% 0; // assumes 1:1 image aspect ratio
            position: relative;
        }
    }
}
</style>
