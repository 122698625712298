<template>
    <div>
        <loading v-if="!anyDataReady" />
        <div v-else>
            <div class="alert alert-primary" v-if="!future.length">
                Du hast <strong>noch keine gemerkten Sendungen</strong>
                <br /><br />
                Tippe im Programm auf
                <font-awesome-icon :icon="['far', 'heart']" />
                um eine Sendung zu merken. Du wirst dann
                <strong>10 Minuten vor Beginn</strong> der Sendung
                benachrichtigt.
            </div>
            <div v-for="(item, index) in future" :key="'f-' + index">
                <h5
                    class="font-weight-bold mt-3 mb-1"
                    v-if="dayjs(item.date).isSame(new Date(), 'day')"
                >
                    Heute
                </h5>
                <h5
                    class="font-weight-bold mt-3 mb-1"
                    v-if="!dayjs(item.date).isSame(new Date(), 'day')"
                >
                    {{ dayjs(item.date).format('dd, DD.MM.YYYY') }}
                </h5>
                <broadcast
                    v-for="(item2, index2) in item.items"
                    :key="'f2' + index2"
                    :layout="BROADCAST_LAYOUT_BOOKMARK"
                    :item="item2"
                    :c="item2.c"
                    @modal-hidden="onModalHidden()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'
export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            loading: false,
            show: false,
        }
    },
    computed: {
        ...mapGetters('series', {
            future: 'groupedByDate',
        }),
        anyDataReady() {
            return (
                this.$store.state.program.init &&
                this.$store.state.series.init &&
                this.loading === false
            )
        },
        sum() {
            return _.reduce(
                this.past,
                function(sum, n) {
                    return sum + n.items.length
                },
                0
            )
        },
    },
    methods: {
        onModalHidden() {
            this.fetchBroadcasts()
        },
        fetchBroadcasts() {
            this.loading = true
            this.$store
                .dispatch('series/broadcasts')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        this.fetchBroadcasts()
    },
}
</script>
