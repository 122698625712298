<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="page-title mb-0">Empfehlungen</h5>
            <div v-for="(item, index) in items" :key="'a-' + index">
                <media-library-item :item="item" :layout="0" :index="index" />
            </div>
            <loading class="mt-2" v-if="appendLoading" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MediaLibraryItem from '../components/medialibrary/Item'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'

export default {
    components: {
        MediaLibraryItem,
        Loading,
    },
    data: function() {
        return {
            dayjs,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            items: 'recommendations',
        }),
        loading() {
            return (
                this.$store.state.medialibrary.recommendations.loading &&
                !this.$store.state.medialibrary.recommendations.append
            )
        },
        appendLoading() {
            return (
                this.$store.state.medialibrary.recommendations.loading &&
                this.$store.state.medialibrary.recommendations.append
            )
        },
    },
    methods: {
        fetchItems(offset) {
            this.$store
                .dispatch('medialibrary/recommendations', {
                    offset: offset !== undefined ? offset : 0,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
        onBottomed() {
            if (this.items.length && !this.appendLoading) {
                this.fetchItems(this.items.length)
            }
        },
    },
    mounted() {
        EventBus.$on('bottomed', this.onBottomed)
        if (!this.items.length) {
            this.fetchItems()
        }
    },
    beforeDestroy() {
        EventBus.$off('bottomed', this.onBottomed)
    },
}
</script>
